import BaseService from "./BaseService";

class AuthService extends BaseService {
  async getRoles() {
    const url = `/api/auth/roles`;
    return await this._fetchGet(null, url);
  }
}

// Singleton
const AUTH_SERVICE = new AuthService();

export default AUTH_SERVICE;
