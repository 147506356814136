import DATASET from "_machina/react/model/Dataset";

export default class DatasetOverridesModel {
  constructor(state) {
    this._state = state;
    if (!state.datasetOverrides) {
      state.datasetOverrides = {
        columns: {},
      };
    }
    if (!state.datasetOverrides.columns) {
      state.datasetOverrides.columns = {};
    }
  }

  getColumnAlias(column, colAsAlias = true) {
    const { datasetOverrides } = this._state;
    let alias = null;
    let colInfo = datasetOverrides.columns[column];
    if (colInfo) {
      alias = colInfo.alias;
    }
    return alias ? alias : colAsAlias ? column : "";
  }

  setColumnAlias(column, alias) {
    const { datasetOverrides } = this._state;

    let set = false;
    if (alias) {
      if (alias.trim().length > 0) {
        let colInfo = datasetOverrides.columns[column];
        if (!colInfo) {
          colInfo = {};
          datasetOverrides.columns[column] = colInfo;
        }
        colInfo.alias = alias;
        set = true;
      }
    }
    if (!set) {
      const colInfo = datasetOverrides.columns[column];
      if (colInfo.alias) {
        delete colInfo.alias;
      }
    }
  }

  getColumnDescription(column) {
    const { datasetOverrides } = this._state;
    let colInfo = datasetOverrides.columns[column];
    let description = null;
    if (colInfo) {
      description = colInfo.description;
    }
    return description;
  }

  setColumnDescription(column, description) {
    const { datasetOverrides } = this._state;

    let set = false;
    if (description) {
      if (description.trim().length > 0) {
        let colInfo = datasetOverrides.columns[column];
        if (!colInfo) {
          colInfo = {};
          datasetOverrides.columns[column] = colInfo;
        }
        colInfo.description = description;
        set = true;
      }
    }
    if (!set) {
      const colInfo = datasetOverrides.columns[column];
      if (colInfo.description) {
        delete colInfo.description;
      }
    }
  }

  setColumnValueAlias(column, value, alias) {
    const { datasetOverrides } = this._state;

    let set = false;
    if (alias) {
      if (alias.trim().length > 0) {
        let colInfo = datasetOverrides.columns[column];
        if (!colInfo) {
          colInfo = {};
          datasetOverrides.columns[column] = colInfo;
        }
        if (!colInfo.values) {
          colInfo.values = {};
        }
        colInfo.values["" + value] = "" + alias;
        set = true;
      }
    }

    if (!set) {
      const colInfo = datasetOverrides.columns[column];
      if (colInfo) {
        if (colInfo.values && colInfo.values["" + value]) {
          delete colInfo.values["" + value];
        }
      }
    }
  }

  getColumnValueAlias(column, value, valAsAlias = true) {
    const { datasetOverrides } = this._state;

    let alias = null;
    let colInfo = datasetOverrides.columns[column];
    if (colInfo) {
      const values = colInfo.values;
      if (values) {
        alias = values["" + value];
      }
    }
    return alias ? alias : valAsAlias ? value : "";
  }

  async loadPreviewRows(limit = 50) {
    const { _state } = this;

    if (!_state.transient.datasetPreview) {
      _state.transient.datasetLimited = -1;

      const limitPlus = limit + 1;
      _state.transient.datasetPreview = await DATASET.previewFile(
        _state.dataset.StoragePath,
        limitPlus
      );
      if (_state.transient.datasetPreview.data.length === limitPlus) {
        _state.transient.datasetPreview.data =
          _state.transient.datasetPreview.data.slice(0, -1);
        _state.transient.datasetLimited = limit;
      }
    }
  }

  async getColumnValues(column, limit = 100) {
    const { _state } = this;

    let datasetUniqueRows = _state.transient.datasetUniqueRows;
    if (!datasetUniqueRows) {
      _state.transient.datasetUniqueRows = {};
      datasetUniqueRows = _state.transient.datasetUniqueRows;
    }

    let values = datasetUniqueRows[column];
    if (!values) {
      values = await DATASET.uniqueValues(
        _state.dataset.StoragePath,
        column,
        limit
      );
      datasetUniqueRows[column] = values;
      console.log(values);
    }

    return values;
  }

  updateState(state, setState) {
    const newState = { ...state };
    newState.datasetOverrides = { ...this._state.datasetOverrides };
    setState(newState);
  }
}
