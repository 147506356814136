import React from "react";

import { FormattedMessage } from "react-intl";

import { uuidv4 } from "_machina/util/Uuid";
import { LOGGER } from "_machina/util/Logging";
import { BaseDialogInner, SelectType } from "./BaseDialog";
import APP from "_machina/react/model/App";
import VIEW_MODEL from "_machina/react/model/ViewModel";

let _setUniqueId = null;
let _setTarget = null;
let _setValue = null;
let _setPlaceholderValue;

export function openTargetDialog(target) {
  _setUniqueId(uuidv4());
  _setTarget(target);

  const targetValue = VIEW_MODEL.getTargetValue();
  _setPlaceholderValue(targetValue);
  _setValue("");
}

export default function TargetDialog() {
  const [uniqueId, setUniqueId] = React.useState(null);
  const [target, setTarget] = React.useState(null);
  const [value, setValue] = React.useState(null);
  const [placeholderValue, setPlaceholderValue] = React.useState(null);

  _setTarget = setTarget;
  _setUniqueId = setUniqueId;
  _setValue = setValue;
  _setPlaceholderValue = setPlaceholderValue;

  const open = target !== null;

  if (!open) return null;

  return (
    <BaseDialogInner
      key={uniqueId}
      title={<FormattedMessage id="selectTargetValue" />}
      open={open}
      callback={(ok) => {
        if (ok) {
          VIEW_MODEL.setTargetValue(value);
          (async () => {
            try {
              await VIEW_MODEL.optimal();
            } catch (e) {
              LOGGER.error("Error determining optimal value", e);
              APP.showErrorMessage(
                <FormattedMessage id="error.model.optimal" />
              );
            }
          })();
        }
        setTarget(null);
      }}
    >
      <SelectType
        feature={target}
        title={target.name}
        value={value}
        setValue={setValue}
        placeholderValue={placeholderValue}
        opts={target.level}
      />
    </BaseDialogInner>
  );
}
