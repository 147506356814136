import { Grid } from "@mui/material";
import AnalysisChart from "./chart";
import AnalysisTable from "./table";
import SCENARIOS_MODEL from "_machina/react/model/ScenariosModel";

const percent = (val) => {
  return (val * 100).toFixed(2);
};

export default function Analysis() {
  const featureNames = [];
  const features = SCENARIOS_MODEL.getFeatures();
  for (let i = 0; i < features.length; i++) {
    const f = features[i];
    featureNames.push(f.getFormattedName());
  }

  const results = SCENARIOS_MODEL.getResults();

  const data = [];
  for (let i = 0; i < results.length; i++) {
    const r = results[i];
    const record = {
      results: {
        dataset: percent(r.dataset_positive),
        model: percent(r.model_positive),
      },
      sets: [],
    };
    data.push(record);
    for (let j = 0; j < features.length; j++) {
      const f = features[j];
      const name = f.getName();
      record.sets.push(f.formatValue(r.subset[name]));
    }
  }

  return (
    <Grid container sx={{ padding: 2 }} spacing={2}>
      <Grid xs={12} item>
        <AnalysisChart featureNames={featureNames} data={data} />
      </Grid>
      <Grid xs={12} item sx={{ marginTop: 0.5 }}>
        <AnalysisTable featureNames={featureNames} data={data} />
      </Grid>
    </Grid>
  );
}
