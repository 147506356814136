import React from "react";

import { FormattedMessage } from "react-intl";

import CommonSwitch from "_machina/react/components/common/fields/CommonSwitch";

export default function ViewModelSwitch({ state, setState }) {
  return (
    <CommonSwitch
      label={<FormattedMessage id="viewModelImmediately" />}
      checked={state.viewModel !== undefined ? state.viewModel : true}
      onChange={() => {
        state.viewModel = !state.viewModel;
        setState({ ...state });
      }}
    />
  );
}
