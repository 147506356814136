import * as React from "react";

// material-ui
import { TablePagination } from "@mui/material";

import CommonTableGridView from "./CommonTableGridView";
import CommonTableView from "./CommonTableView";
import PREFS from "_machina/Prefs";

const CommonTable = (props) => {
  const {
    isSkeleton,
    tableName,
    cardView,
    disableSelection,
    headCells,
    updateResultsCallback,
    renderRow,
    renderCard,
    renderToolbarItems,
    results,
  } = props;

  const [orderAscending, setOrderAscending] = React.useState(
    PREFS.getBoolPreference(tableName + ".order", true)
  );
  const [orderBy, setOrderBy] = React.useState(
    PREFS.getPreference(tableName + ".orderBy", headCells[0].id)
  );
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    cardView
      ? PREFS.getIntPreference(tableName + ".cardsRowsPerPage", 6)
      : PREFS.getIntPreference(tableName + ".rowsPerPage", 5)
  );
  const [updateResults, setUpdateResults] = React.useState(false);

  const totalCount = results?.TotalCount ? results.TotalCount : 0;
  const rows = results?.Results ? results.Results : [];

  let originalPage = page;
  let currentPage = page;

  if (page > 0 && totalCount < page * rowsPerPage + 1) {
    currentPage = totalCount > 0 ? ((totalCount - 1) / rowsPerPage) | 0 : 0;
  }

  // Check selection
  // eslint-disable-next-line
  React.useEffect(() => {
    const selMap = {};
    for (let i = 0; i < selected.length; i++) {
      selMap[selected[i]] = true;
    }

    const newSel = [];
    for (let i = 0; i < rows.length; i++) {
      const id = rows[i].ID;
      if (selMap[id] === true) {
        newSel.push(id);
      }
    }

    if (selected.length !== newSel.length) {
      setSelected(newSel);
    }
  });

  // Fire every time to see if page should be changed
  // eslint-disable-next-line
  React.useEffect(() => {
    if (currentPage !== originalPage) {
      setPage(currentPage);
    }
  });

  // Sorting and paging effect
  React.useEffect(() => {
    (async () => {
      if (isSkeleton || updateResults) {
        await updateResultsCallback(page, rowsPerPage, orderBy, orderAscending);
      }
    })();
    // eslint-disable-next-line
  }, [rowsPerPage, page, orderAscending, orderBy, isSkeleton, updateResults]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && !orderAscending;
    setUpdateResults(true);
    setOrderAscending(isAsc);
    setOrderBy(property);

    PREFS.setPreference(tableName + ".orderBy", property);
    PREFS.setPreference(tableName + ".order", isAsc);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setUpdateResults(true);
  };

  const handleChangeRowsPerPage = (event) => {
    const rows = parseInt(event?.target.value, 10);
    setUpdateResults(true);
    setRowsPerPage(rows);
    PREFS.setPreference(
      cardView ? tableName + ".cardsRowsPerPage" : tableName + ".rowsPerPage",
      rows
    );
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <div>
      {!cardView ? (
        <CommonTableView
          isSkeleton={isSkeleton}
          disableSelection={disableSelection}
          handleRequestSort={handleRequestSort}
          headCells={headCells}
          isSelected={isSelected}
          orderAscending={orderAscending}
          orderBy={orderBy}
          renderRow={renderRow}
          renderToolbarItems={renderToolbarItems}
          rows={rows}
          selected={selected}
          setSelected={setSelected}
        />
      ) : (
        <CommonTableGridView rows={rows} renderCard={renderCard} />
      )}
      {!isSkeleton && (
        <TablePagination
          rowsPerPageOptions={cardView ? [6, 12, 24] : [5, 10, 25]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
};

export default CommonTable;
