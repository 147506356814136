import * as React from "react";

// material-ui
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";

import { useTheme } from "@mui/system";

import MainCard from "ui-component/cards/MainCard";
import CommonTableEditColumn from "_machina/react/components/common/table/CommonTableEditColumn";
import CommonTooltip from "_machina/react/components/common/CommonTooltip";
import { openEditDialog, EditDialog } from "./EditDialog";
import { FormattedMessage } from "react-intl";

export function PreviewTable({
  columns,
  rows,
  state,
  setState,
  overridesModel,
}) {
  const theme = useTheme();

  return (
    <MainCard
      key={"PreviewTable"}
      headerSX={{ m: 1.5, p: 0 }}
      sx={{
        backgroundColor:
          theme.palette.mode === "dark"
            ? theme.palette.dark.light + 10
            : theme.palette.grey[200],
        borderColor:
          theme.palette.mode === "dark"
            ? theme.palette.dark.light + 15
            : theme.palette.grey[200],
      }}
      titleContent={
        <Typography variant="h4">
          <FormattedMessage id="datasetContent" />
        </Typography>
      }
      content={false}
    >
      <TableContainer sx={{ maxHeight: 340 }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow
              sx={{
                "& th": {
                  paddingTop: 1,
                  paddingBottom: 1,
                },
              }}
            >
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CommonTooltip
                      title={overridesModel.getColumnDescription(column.id)}
                      placement="bottom"
                      arrow
                    >
                      <Box>{column.label}</Box>
                    </CommonTooltip>
                    <Box sx={{ paddingLeft: 0.5, opacity: 0.8 }}>
                      <CommonTableEditColumn
                        size="small"
                        color={"info"}
                        onClick={() => {
                          openEditDialog(
                            column.id,
                            state,
                            setState,
                            (model) => {
                              model.updateState(state, setState);
                            }
                          );
                        }}
                      />
                    </Box>
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, id) => (
              <TableRow key={id}>
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EditDialog />
    </MainCard>
  );
}
