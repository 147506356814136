import React, { useState } from "react";
import { useIntl } from "react-intl";

import { useTheme } from "@mui/material/styles";
import ReactApexChart from "react-apexcharts";

import MainCard from "ui-component/cards/MainCard";

const ScenariosDistributionChart = ({
  distribution,
  divSx,
  sparkline,
  name,
  height,
  footer,
}) => {
  const [options, setOptions] = useState(null);

  const theme = useTheme();
  const intl = useIntl();

  React.useEffect(() => {
    const { primary } = theme.palette.text;
    const darkLight = theme.palette.dark.light;

    const data = [];
    const dataColors = [];
    const categories = [];
    const categoriesColors = [];

    const dist = distribution;
    for (let i = 0; i < dist.length; i++) {
      const d = dist[i];
      data.push(d[1]);
      dataColors.push(primary);
      categories.push("" + d[0]);
      categoriesColors.push(primary);
    }

    // if (STRING_UTIL.isOnlyDates(categories)) {
    //     for (let cidx = 0; cidx < categories.length; cidx++) {
    //         categories[cidx] = STRING_UTIL.stripTime(categories[cidx]);
    //     }
    // }

    setOptions({
      data: data,
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: sparkline ? sparkline : false,
        },
        events: {
          dataPointSelection: (e, x, z) => {
            // const index = z.dataPointIndex;
            // console.log(dist[index][0]);
            // const category = dist[index][0]; //categories[index];
            // if (onCategoryClick) onCategoryClick(category);
          },
          xAxisLabelClick: (e, x, y) => {
            // console.log(dist[y.labelIndex][0]);
            // const category = dist[y.labelIndex][0]; //categories[y.labelIndex];
            // if (onCategoryClick) onCategoryClick(category);
          },
        },
        parentHeightOffset: 0,
      },
      plotOptions: {
        bar: {
          distributed: true,
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: categories,
        labels: {
          style: {
            colors: categoriesColors,
          },
        },
        features: {
          sort: false,
        },
      },
      // colors: [successDark],
      yaxis: {
        labels: {
          style: {
            colors: dataColors,
          },
        },
      },
      grid: {
        borderColor: darkLight + 20,
      },
      tooltip: {
        fixed: {
          enabled: true,
        },
        theme: "dark",
      },
      legend: {
        show: false,
      },
    });
    // eslint-disable-next-line
  }, [distribution]);

  if (!options) return;

  return (
    <div>
      <MainCard
        contentSX={{
          m: 0,
          my: 0,
          p: 0,
          px: 2,
          py: 0,
          height: "100%",
          paddingBottom: "0 !important",
        }}
        sx={{
          backgroundColor:
            theme.palette.mode === "dark"
              ? theme.palette.dark.light + 10
              : theme.palette.grey[200],
          borderColor:
            theme.palette.mode === "dark"
              ? theme.palette.dark.light + 15
              : theme.palette.grey[200],
          ...divSx,
        }}
        // titleContent={hideTitle ? null : <Typography variant="h4"><FormattedMessage id="valueDistribution" /></Typography>}
      >
        <div style={{ height: "100%" }}>
          <ReactApexChart
            options={options}
            series={[
              {
                name: intl.formatMessage({ id: "count" }),
                data: options?.data ? options.data : [],
              },
            ]}
            type="bar"
            height="100%"
            width="100%"
          />
        </div>
      </MainCard>
    </div>
  );
};

export default ScenariosDistributionChart;
