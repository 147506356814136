import MainCard from "ui-component/cards/MainCard";

import {
  Box,
  Chip,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";

function Progress({ val, isModel }) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        sx={{ color: isModel ? "#2196f3" : "#01c854" }}
        value={val}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="#DDD"
        >{`${Math.round(val)}%`}</Typography>
      </Box>
    </Box>
  );
}

function Row({ data, index }) {
  const sets = data.sets;
  const dataset = data.results.dataset;
  const model = data.results.model;

  return (
    <TableRow>
      <TableCell key={index + "rank"} align="center">
        <Chip
          label={index + 1}
          variant="outlined"
          sx={{
            color: "#50a4e7",
            fontSize: "1em",
            borderColor: "#50a4e7",
            borderWidth: "1px",
            fontWeight: "bold",
          }}
        />
      </TableCell>

      {sets.map((d, index) => {
        return (
          <TableCell key={index + d} align="center">
            {d}
          </TableCell>
        );
      })}
      <TableCell key={index + "dataset"} align="center">
        <Progress val={Number(dataset)} isModel={false} />
      </TableCell>
      <TableCell key={index + "model"} align="center">
        <Progress val={Number(model)} isModel={true} />
      </TableCell>
    </TableRow>
  );
}

export default function AnalysisTable({ data, featureNames }) {
  return (
    <MainCard
      title={"Top Feature Set Details (Limited to 10)"}
      sx={{ backgroundColor: "#2e3556" }}
    >
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow key={"_header"}>
              <TableCell align="center">Rank</TableCell>
              {featureNames.map((f, index) => {
                return (
                  <TableCell key={"" + index} align="center">
                    {f}
                  </TableCell>
                );
              })}
              <TableCell key={"_dataset"} align="center">
                Dataset
              </TableCell>
              <TableCell key={"_model"} align="center">
                Model
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((d, index) => {
              return <Row key={"row" + index} data={d} index={index} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </MainCard>
  );
}
