import React from "react";

import { useState } from "react";
import { MenuItem } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { LOGGER } from "_machina/util/Logging";
import DistributionChart from "../common/DistributionChart";
import FileAnalysisModel from "../FileAnalysisModel";

import APP from "_machina/react/model/App";
import DATASET from "_machina/react/model/Dataset";
import CommonModelComponent from "../common/CommonModelComponent";
import CommonStatusComponent from "../common/CommonStatusComponent";
import ColumnsComponent from "../common/ColumnsComponent";
import DatasetOverridesModel from "../DatasetOverridesModel";
import TimeSeriesChart from "../common/TimeSeriesChart";

async function invokeAnalyze(model, state, setState, onPrePost) {
  try {
    const excludedColumns = state?.dataset?.AnalysisResults?.excludedColumns;
    const excludedPredictionColumns =
      state?.dataset?.AnalysisResults?.excludedPredictionColumns;

    const path = model.getStoragePath();
    const clone = model.cloneForPost();

    if (onPrePost) onPrePost(clone);

    const result = await DATASET.analyzeFile(
      path,
      clone.getAnalysis()["columns"],
      excludedColumns
    );
    result.excludedColumns = excludedColumns;
    result.excludedPredictionColumns = excludedPredictionColumns;
    state.dataset.AnalysisResults = result;

    setState({ ...state });
  } catch (e) {
    LOGGER.error("Error analyzing dataset", e);
    APP.showErrorMessage(<FormattedMessage id="error.dataset.analyzing" />);
  }
}

function StatusComponent({ state, setState, selectedColumn }) {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const model = selectedColumn.getColumns().getModel();

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const possibleTypes = selectedColumn.getPossibleTypes();
  possibleTypes.sort();
  const menuItems = [];

  const switchType = (type) => {
    invokeAnalyze(model, state, setState, (clone) => {
      const col = clone.getColumns().getColumn(selectedColumn.getName());
      col.setType(type);
    });
  };

  const currentType = selectedColumn.getType();
  for (let i = 0; i < possibleTypes.length; i++) {
    const type = possibleTypes[i];
    if (type === currentType) continue;
    menuItems.push(
      <MenuItem
        key={type}
        onClick={() => {
          closeMenu();
          switchType(type);
        }}
      >
        {selectedColumn.getNameForType(type)}
      </MenuItem>
    );
  }

  return (
    <CommonStatusComponent
      menuAnchorEl={menuAnchorEl}
      setMenuAnchorEl={setMenuAnchorEl}
      selectedColumn={selectedColumn}
      menuItems={menuItems}
      closeMenu={closeMenu}
      typeLabel={
        <>
          <FormattedMessage id="columnType" />:
        </>
      }
      typeValue={selectedColumn.getTypeName()}
    />
  );
}

export default function ModelFeaturesComponent({ validator, state, setState }) {
  const [selectedColumn, setSelectedColumn] = React.useState(null);

  if (!state?.dataset?.AnalysisResults) return;

  const fileAnalysis = new FileAnalysisModel(state.dataset.AnalysisResults);
  const overridesModel = new DatasetOverridesModel(state);
  const columns = fileAnalysis.getColumns();
  const selCol = columns.getSelectedColumn(selectedColumn);
  const tsDistribution = selCol.getTimeSeriesDistribution();

  return (
    <CommonModelComponent
      validator={validator}
      fileModel={fileAnalysis}
      columnsComponent={
        <ColumnsComponent
          overridesModel={overridesModel}
          isFeatures={true}
          state={state}
          setState={setState}
          columns={columns}
          selectedColumn={selCol}
          setSelectedColumn={setSelectedColumn}
          onExcludedChanged={() => {
            invokeAnalyze(selCol.getColumns().getModel(), state, setState);
          }}
        />
      }
      chartComponent={
        tsDistribution ? (
          <TimeSeriesChart
            distribution={tsDistribution}
            overridesModel={overridesModel}
          />
        ) : (
          <DistributionChart
            selectedColumn={selCol}
            overridesModel={overridesModel}
          />
        )
      }
      statusComponent={
        <StatusComponent
          selectedColumn={selCol}
          state={state}
          setState={setState}
        />
      }
    />
  );
}
