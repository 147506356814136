import * as React from "react";

import { useIntl } from "react-intl";

import { IconButton, Tooltip } from "@mui/material";

// assets
import DeleteIcon from "@mui/icons-material/Delete";

export default function DeleteButton({ tooltip, disabled, onClick }) {
  const intl = useIntl();
  return (
    <Tooltip title={tooltip || intl.formatMessage({ id: "delete" })}>
      <div>
        <IconButton disabled={disabled} onClick={onClick}>
          <DeleteIcon />
        </IconButton>
      </div>
    </Tooltip>
  );
}
