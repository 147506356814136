import * as React from "react";

import { FormattedMessage } from "react-intl";

import { IconButton, Tooltip } from "@mui/material";

import QueryStatsTwoToneIcon from "@mui/icons-material/QueryStatsTwoTone";

export default function CommonTableViewColumn({ onClick }) {
  return (
    <Tooltip title={<FormattedMessage id="view" />}>
      <IconButton
        color="text-primary"
        size="large"
        onClick={(e) => {
          e.stopPropagation();
          onClick(e);
        }}
      >
        <QueryStatsTwoToneIcon sx={{ fontSize: "1.3rem" }} />
      </IconButton>
    </Tooltip>
  );
}
