import { useTheme } from "@mui/material/styles";
import {
  Button,
  Grid,
  IconButton,
  Tooltip,
  Stack,
  Typography,
} from "@mui/material";

import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import SettingsBackupRestoreRoundedIcon from "@mui/icons-material/SettingsBackupRestoreRounded";
import TagRoundedIcon from "@mui/icons-material/TagRounded";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import SCENARIOS_MODEL from "_machina/react/model/ScenariosModel";
import { LOGGER } from "_machina/util/Logging";
import APP from "_machina/react/model/App";

const Column = ({ name, title, children, type }) => {
  const theme = useTheme();
  const bgcolor =
    theme.palette.mode === "dark"
      ? theme.palette.background.default
      : theme.palette.primary.light;
  return (
    <>
      <div
        style={{
          backgroundColor: bgcolor,
          minWidth: "25%",
          maxWidth: "33.3%",
          borderRadius: 8,
          userSelect: "none",
          height: "100%",
          margin: 8,
          flexGrow: 1,
        }}
      >
        <div
          style={{
            backgroundColor: bgcolor,
            border: "1px solid rgb(17, 25, 54)",
            borderRadius: "8px",
            userSelect: "none",
            padding: "8px 16px 14px",
          }}
        >
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs zeroMinWidth sx={{ mb: 1.5 }}>
              {title}
              <div>
                {type === "numeric" && (
                  <Stack
                    direction="row"
                    spacing={0.5}
                    alignItems="center"
                    sx={{ marginTop: "5px" }}
                  >
                    <TagRoundedIcon
                      color="secondary"
                      sx={{ fontSize: "0.875rem" }}
                    />
                    <Typography
                      sx={{ paddingTop: "2px" }}
                      color="secondary"
                      fontSize={"10px"}
                    >
                      Numerical
                    </Typography>
                  </Stack>
                )}
                {type === "categorical" && (
                  <Stack
                    direction="row"
                    spacing={0.5}
                    alignItems="center"
                    sx={{ marginTop: "5px" }}
                  >
                    <FormatListBulletedRoundedIcon
                      color="secondary"
                      sx={{ fontSize: "0.875rem" }}
                    />
                    <Typography
                      sx={{ paddingTop: "2px" }}
                      color="secondary"
                      fontSize={"10px"}
                    >
                      Categorical
                    </Typography>
                  </Stack>
                )}
              </div>
            </Grid>
            <Grid item sx={{ mb: 1.5 }}>
              <Tooltip title={"Reset"}>
                <IconButton size="large">
                  <SettingsBackupRestoreRoundedIcon
                    fontSize="small"
                    aria-controls="menu-simple-card"
                    aria-haspopup="true"
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Delete"}>
                <IconButton
                  onClick={async () => {
                    try {
                      await SCENARIOS_MODEL.removeFeature(name);
                    } catch (e) {
                      LOGGER.error(
                        "An error occurred attempting to remove the feature",
                        e
                      );
                      APP.showErrorMessage(
                        "An error occurred attempting to remove the feature"
                      );
                    }
                  }}
                  size="large"
                >
                  <DeleteTwoToneIcon
                    fontSize="small"
                    aria-controls="menu-simple-card"
                    aria-haspopup="true"
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          {children}
          <Button sx={{ width: "100%" }}>Add</Button>
        </div>
      </div>
    </>
  );
};

export default Column;
