import React from "react";

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import { Slide } from "@mui/material";
import { useTheme } from "@mui/system";

import APP from "./model/App";

function TransitionSlideDown(props) {
  return <Slide {...props} direction="down" />;
}

const slideDown = TransitionSlideDown;

function AppSnackbar(props) {
  const theme = useTheme();
  const [message, setMessage] = React.useState(null);
  const [counter, setCounter] = React.useState(1);
  APP._setMessage = setMessage;

  const onClose = (e, reason) => {
    if (reason !== "clickaway") {
      setMessage(null);
    }
  };

  React.useEffect(() => {
    setCounter((counter) => counter + 1);
  }, [message]);

  const msg = message?.message ? message.message : null;
  let severity = message?.severity ? message.severity : null;
  const actionTitle = message?.actionTitle ? message.actionTitle : null;
  const actionOnClick = message?.actionOnClick ? message.actionOnClick : null;

  if (!msg) return;

  return (
    <Snackbar
      key={counter}
      TransitionComponent={slideDown}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={message !== null}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <Alert
        onClose={onClose}
        variant="filled"
        color={severity}
        severity={severity ? severity : "info"}
        action={
          actionTitle && actionOnClick ? (
            <Button
              sx={{
                ml: 4,
                mb: 0.5,
                borderColor: theme.palette.success.dark,
                color: theme.palette.success.dark,
              }}
              variant="outlined"
              size="small"
              onClick={() => {
                actionOnClick();
              }}
            >
              {actionTitle}
            </Button>
          ) : null
        }
      >
        {msg}
      </Alert>
    </Snackbar>
  );
}

export default AppSnackbar;
