import MainCard from "ui-component/cards/MainCard";

import React, { useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";

// third-party
import ReactApexChart from "react-apexcharts";

// project import
import useConfig from "hooks/useConfig";

// chart options
const columnChartOptions = {
  chart: {
    type: "bar",
    height: 350,
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "55%",
      // endingShape: 'rounded',
      borderRadius: 4,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },

  yaxis: {
    title: {
      text: "$ (thousands)",
    },
  },
  fill: {
    opacity: 1,
  },
  tooltip: {
    // custom: function({series, seriesIndex, dataPointIndex, w}) {
    //     return '<div class="arrow_box">' +
    //     '<span>' + w.globals.labels[dataPointIndex] + '</span>' +
    //     '<span>' + series[seriesIndex][dataPointIndex] + '</span>' +
    //     '</div>'
    // }
    // x: {
    //     formatter(val) {
    //         return `${val} ssss`;
    //     }
    // },
    // y: {
    //     formatter(val) {
    //         return `$ ${val} thousands`;
    //     }
    // }
  },
  legend: {
    show: true,
    fontFamily: `'Roboto', sans-serif`,
    position: "bottom",
    offsetX: 10,
    offsetY: 10,
    labels: {
      useSeriesColors: false,
    },
    markers: {
      width: 16,
      height: 16,
      radius: 5,
    },
    itemMargin: {
      horizontal: 15,
      vertical: 8,
    },
  },
};

// const ANALYSIS_DATA = [
//     {
//         sets: {
//             age: "18-29",
//             overtime: "Yes",
//             travel: "Rarely"
//         },
//         results: {
//             dataset: 95.5,
//             model: 45
//         }
//     }
// ]

const getSeriesState = (data) => {
  const dataset = [];
  const model = [];
  const state = [
    {
      name: "Dataset",
      data: dataset,
    },
    {
      name: "Model",
      data: model,
    },
  ];

  for (let i = 0; i < data.length; i++) {
    const d = data[i];
    model[i] = d.results.model;
    dataset[i] = d.results.dataset;
  }

  return state;
};

const getCategories = (data) => {
  const cats = [];
  for (let i = 0; i < data.length; i++) {
    cats.push("" + i);
  }
  return cats;
};

// ==============================|| COLUMN CHART ||============================== //

const ApexColumnChart = ({ data, featureNames }) => {
  const theme = useTheme();
  const { navType } = useConfig();

  const { primary } = theme.palette.text;
  const darkLight = theme.palette.dark.light;
  const grey200 = theme.palette.grey[200];

  const primaryMain = theme.palette.primary.main;
  const successDark = theme.palette.success.dark;

  const [series] = useState(
    getSeriesState(data)
    /*[
        {
            name: 'Dataset',
            data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
        },
        {
            name: 'Model',
            data: [76, 85, 101, 98, 87, 90, 91, 56, 94]
        },

    ]*/
  );

  const [options, setOptions] = useState(null);

  React.useEffect(() => {
    setOptions({
      ...columnChartOptions,
      colors: [successDark, primaryMain],
      xaxis: {
        labels: {
          style: {
            colors: [
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
            ],
          },
          formatter: (x) => {
            return parseInt(x) + 1;
          },
        },
        // categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
        categories: getCategories(data),
        show: true,
      },
      yaxis: {
        min: 0,
        // max: 100,
        labels: {
          style: {
            colors: [primary],
          },
          formatter: (x) => {
            return x.toFixed(2) + "%";
          },
        },
      },
      grid: {
        borderColor: navType === "dark" ? darkLight + 20 : grey200,
      },
      tooltip: {
        theme: navType === "dark" ? "dark" : "light",

        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const s = data[dataPointIndex];
          const isModel = seriesIndex === 1;
          const text =
            (isModel ? "Model: " : "Dataset: ") +
            (isModel ? s.results.model : s.results.dataset) +
            "%";
          const box = isModel
            ? `<div style="width: 12px; height: 12px; border-radius: 3px; background-color: rgb(33, 150, 243); margin-right: 8px; margin-top: 2px"></div>`
            : `<div style="width: 12px; height: 12px; border-radius: 3px; background-color: #01c854; margin-right: 8px; margin-top: 2px"></div>`;

          let content = "";
          for (let i = 0; i < featureNames.length; i++) {
            const name = featureNames[i];
            content +=
              i === 0
                ? `<div style="display: flex;">`
                : `<div style="display: flex; padding-top: 6px;">`;
            content += `<div style="font-weight: bold; width: 120px; padding-right: 5px; text-overflow: ellipsis; overflow: hidden;">${name}</div>`;
            content += `<div>${s.sets[i]}</div>`;
            content += "</div>";
          }

          const out = `
            <div style="width: fit-content; background-color: rgba(0,0,0,.5);">                    
              <div style="padding: 8px; background-color: rgba(0,0,0,.7); display:flex; font-weight: bold">
                ${box}
                ${text}
              </div>
              <div style="padding: 8px; background-color: rgba(55,55,55,.4); color: #CCC">
                ${content}
              </div>
            </div>
          `;
          return out;
        },
      },
      legend: {
        labels: {
          colors: "grey.500",
        },
      },
    });
    // eslint-disable-next-line
  }, []);

  if (!options) return;

  return (
    // <div id="chart">
    <ReactApexChart options={options} series={series} type="bar" height={350} />
    // </div>
  );
};

export default function AnalysisChart({ data, featureNames }) {
  return (
    <MainCard
      title={"Top Feature Sets (Limited to 10)"}
      sx={{ backgroundColor: "#2e3556" }}
    >
      <ApexColumnChart featureNames={featureNames} data={data} />
    </MainCard>
  );
}
