import APP from "_machina/react/model/App";

export function getNameForModelType(type) {
  const i18n = APP.getI18n();

  switch (type) {
    case "classification":
      return i18n.formatMessage({ id: "classification" }); // "Classification"
    case "regression":
      return i18n.formatMessage({ id: "regression" }); // "Regression"
    case "timeseries":
      return i18n.formatMessage({ id: "timeseries" }); // "Time series"
    case "invalid":
      return i18n.formatMessage({ id: "invalid" }); // "Invalid"
    default:
      return i18n.formatMessage({ id: "unknownType" }, { type: type }); // "Unknown (" + type + ")"
  }
}

export class Column {
  constructor(columns, name, column) {
    this._columns = columns;
    this._name = name;
    this._column = column;
  }

  getStatusMessage() {
    const i18n = APP.getI18n();
    const msg = this._column?.feature?.statusMessage;
    if (msg === "Has categories that do not have enough values") {
      return i18n.formatMessage({ id: "column.lacksValues" });
    }
    if (msg === "Must have more than one unique value") {
      return i18n.formatMessage({ id: "column.uniqueValues" });
    }
    return msg;
  }

  getShortStatusMessage() {
    const i18n = APP.getI18n();
    const msg = this._column?.feature?.statusMessage;
    if (msg === "Has categories that do not have enough values") {
      return i18n.formatMessage({ id: "column.lacksValues.short" });
    }
    if (msg === "Must have more than one unique value") {
      return i18n.formatMessage({ id: "column.uniqueValues.short" });
    }
    return msg;
  }

  getColumns() {
    return this._columns;
  }

  getName() {
    return this._name;
  }

  isEnabled() {
    return /*this.isUsable() &&*/ !this.isExcluded();
  }

  isPredictEnabled() {
    return this.isUsableForPredict() && !this.isPredictExcluded();
  }

  isExcluded() {
    return this._columns.getExcludedColumns()[this.getName()] ? true : false;
  }

  isPredictExcluded() {
    return this._columns.getExcludedPredictionColumns()[this.getName()]
      ? true
      : false;
  }

  setExcluded(excluded) {
    if (excluded) {
      this._columns.getExcludedColumns()[this.getName()] = true;
    } else {
      delete this._columns.getExcludedColumns()[this.getName()];
    }
  }

  setPredictExcluded(excluded) {
    if (excluded) {
      this._columns.getExcludedPredictionColumns()[this.getName()] = true;
    } else {
      delete this._columns.getExcludedPredictionColumns()[this.getName()];
    }
  }

  isUsable() {
    return this._column?.feature?.status === "usable";
  }

  isUsableForPredict() {
    return this._column?.predict?.modelType !== "invalid";
  }

  getNameForType(type) {
    const i18n = APP.getI18n();

    switch (type) {
      case "numeric":
        return i18n.formatMessage({ id: "numeric" });
      case "categorical":
        return i18n.formatMessage({ id: "categorical" });
      case "text":
        return i18n.formatMessage({ id: "text" }); // "Invalid"
      case "datetime":
        return i18n.formatMessage({ id: "datetime" }); // "Invalid"
      default:
        return i18n.formatMessage({ id: "unknownType" }, { type: type }); // "Unknown (" + type + ")"
    }
  }

  getType() {
    return this._column.type;
  }

  getTypeName() {
    return this.getNameForType(this._column.type);
  }

  getPossibleTypes() {
    return this._column.possibleTypes;
  }

  getNameForModelType(type) {
    return getNameForModelType(type);
  }

  getModelType() {
    return this._column.predict.modelType;
  }

  getModelTypeName() {
    return this.getNameForModelType(this._column.predict.modelType);
  }

  getPossibleModelTypes() {
    return this._column.predict.possibleModelTypes;
  }

  setType(type) {
    this._column.type = type;
  }

  setModelType(type) {
    this._column.predict.modelType = type;
  }

  getDistribution() {
    return this._column.distribution;
  }

  getTimeSeriesDistribution() {
    return this._column.tsDistribution;
  }

  getEmptyValues() {
    return this._column.emptyValues;
  }

  getUniqueValues() {
    return this._column.uniqueValues;
  }

  stripForPost() {
    // const col = this._column;
    // delete col.distribution;
    // delete col.uniqueValues;
    // delete col.possibleTypes;
    // delete col.predict.possibleModelTypes;
    // delete col.predict.statusMessage;
    // delete col.uniqueValues;
    // delete col.emptyValues;
  }
}

export class Columns {
  constructor(model, analysisResults) {
    this._model = model;
    this._analysisResults = analysisResults;
    const columns = analysisResults.columns;
    if (!columns) {
      throw new Error("No columns found in analysis results");
    }

    this._columnsByName = {};
    this._columns = [];
    for (let name in columns) {
      const col = new Column(this, name, columns[name]);
      this._columnsByName[name] = col;
      this._columns.push(col);
    }

    if (!columns.length === 0) {
      throw new Error("No columns found in analysis results");
    }
  }

  getModel() {
    return this._model;
  }

  getTimeSeriesDistribution() {
    for (let i = 0; i < this._columns.length; i++) {
      const col = this._columns[i];
      const tsDistribution = col.getTimeSeriesDistribution();
      if (tsDistribution) {
        return tsDistribution;
      }
    }
    return null;
  }

  getEnabledColumnsCount() {
    let count = 0;
    for (let i = 0; i < this._columns.length; i++) {
      const col = this._columns[i];
      if (col.isEnabled()) {
        count++;
      }
    }
    return count;
  }

  getEnabledPredictionColumnsCount() {
    let count = 0;
    for (let i = 0; i < this._columns.length; i++) {
      const col = this._columns[i];
      if (col.isPredictEnabled()) {
        count++;
      }
    }
    return count;
  }

  getExcludedColumns() {
    return this._analysisResults.excludedColumns;
  }

  getExcludedPredictionColumns() {
    return this._analysisResults.excludedPredictionColumns;
  }

  getColumn(name) {
    return this._columnsByName[name];
  }

  getSelectedColumn(colName) {
    const col = this._columnsByName[colName];
    return col ? col : this.getFeaturesByStatus()[0];
  }

  getAllColumns() {
    return this._columns;
  }

  getFeaturesByStatus(isPredict = false) {
    const ret = [...this._columns];
    ret.sort((a, b) => {
      return a.getName().trim().localeCompare(b.getName().trim());
    });

    // ret.sort((a, b) => {
    //     if (a.isEnabled() && !b.isEnabled()) {
    //         return -1;
    //     } else if (b.isEnabled() && !a.isEnabled()) {
    //         return 1;
    //     }
    //     return 0;
    // })

    if (isPredict) {
      return ret.sort((a, b) => {
        if (a.isUsableForPredict() && !b.isUsableForPredict()) {
          return -1;
        } else if (b.isUsableForPredict() && !a.isUsableForPredict()) {
          return 1;
        }
        return 0;
      });
    }

    return ret.sort((a, b) => {
      if (a.isUsable() && !b.isUsable()) {
        return -1;
      } else if (b.isUsable() && !a.isUsable()) {
        return 1;
      }
      return 0;
    });
  }
}

export default class FileAnalysisModel {
  constructor(analysis) {
    this._analysis = analysis;
  }

  disableUnusableColumns() {
    const cols = this.getColumns().getAllColumns();
    for (let i = 0; i < cols.length; i++) {
      const col = cols[i];
      if (!col.isUsable()) {
        col.setExcluded(true);
      }
    }
  }

  getAnalysis() {
    return this._analysis;
  }

  isAvailable() {
    return this._analysis ? true : false;
  }

  getTotalRows() {
    return this._analysis.totalRows;
  }

  getTotalColumns() {
    return this._analysis.totalColumns;
  }

  isUsable() {
    return this._analysis.status !== "invalid";
  }

  hasIssues() {
    return this._analysis.status === "issues";
  }

  getColumns() {
    if (!this._columns) {
      this._columns = new Columns(this, this._analysis);
    }
    return this._columns;
  }

  getStoragePath() {
    return this._analysis.datasetUri;
  }

  getExcludedColumns() {
    return this._analysis.excludedColumns;
  }

  cloneForPost() {
    const clone = new FileAnalysisModel(
      JSON.parse(JSON.stringify(this._analysis))
    );
    const analysisClone = clone.getAnalysis();

    analysisClone.predictionColumns = [];
    const excludedColumns = analysisClone.excludedColumns;
    const excludedPredictionColumns = analysisClone.excludedPredictionColumns;

    let cols = clone.getColumns().getAllColumns();
    for (let i = 0; i < cols.length; i++) {
      const col = cols[i];
      col.stripForPost();
      const name = col.getName();
      //if (!col.isUsable()) {
      //    excludedColumns[name] = false;
      //}
      if (col.isUsableForPredict() && !excludedPredictionColumns[name]) {
        analysisClone.predictionColumns.push(name);
      }
    }

    const excluded = [];
    for (let k in excludedColumns) {
      excluded.push(k);
    }

    analysisClone.excludedColumns = excluded;
    // delete analysisClone.excludedPredictionColumns;
    // delete analysisClone.datasetUri;
    // delete analysisClone.status;
    // delete analysisClone.totalColumns;
    // delete analysisClone.totalRows;

    return clone;
  }
}
