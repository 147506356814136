import { LOGGER } from "_machina/util/Logging";
import BaseViewModel from "./BaseViewModel";
import SUB_MODEL_SERVICE from "_machina/service/SubModelService";
import MODEL from "./Model";

import { openFeatureDialog } from "../pages/view-model-page/dialogs/FeatureDialog";
import { openTargetDialog } from "../pages/view-model-page/dialogs/TargetDialog";

export const EMPTY_CONFIG = "emptyConfig";

class ViewModel extends BaseViewModel {
  /**
   * @constructor
   */
  constructor() {
    super();
    this._setSelectedSubModel = null;
    this._setFeatures = null;
    this._setTarget = null;
    this._setTargetValue = null;
    this._setPredictionResult = null;
    this._setPredictionCount = null;
    this._setValues = null;
    this._setPredictMode = null;
    this._setTimeSeriesDistribution = null;
  }

  getTimeSeriesDistribution() {
    return this._timeSeriesDistribution;
  }

  async _calcTimeSeriesDistribution() {
    if (this._predictionDistribution) {
      for (let f in this._features) {
        try {
          const colInfo = await this.getColumnInfo(f);
          this._timeSeriesDistribution = colInfo.getTimeSeriesDistribution();
          this._setTimeSeriesDistribution(this._timeSeriesDistribution);
        } catch (e) {
          LOGGER.info(e);
        }
        break;
      }
    }
  }

  openFeatureEditor(featureName) {
    const feature = this.getFeature(featureName);
    if (feature) {
      openFeatureDialog(feature);
    }
  }

  openTargetEditor() {
    if (this._target) {
      openTargetDialog(this._target);
    }
  }

  isPredictMode(mode) {
    return this._predictMode;
  }

  async switchModes(isPredictMode, skipSelectSubModel) {
    this.showStatus(null);
    try {
      this._predictMode = isPredictMode;

      // Reset values prior to switching modes
      this._features = null;
      this._featuresSorted = [];
      this._defaultValues = null;
      this._values = null;
      this._target = null;
      this._targetValue = null;
      this._predictionResult = null;
      this._predictionDistribution = null;
      this._timeSeriesDistribution = null;

      if (!skipSelectSubModel) {
        await this.onSelectSubModel(this._selectedSubModelId, true);
      }
    } finally {
      // TODO: We always switch the model, even on an error
      // A better approach would be to collect all values in an object and swap
      // them all at once if it is successful.
      if (!skipSelectSubModel) {
        this._setPredictMode(this._predictMode);
      }
      this.hideStatus();
    }
  }

  getOverrides() {
    return this._datasetOverridesModel;
  }

  getFeature(featureName) {
    if (!this._features) return null;
    return this._features[featureName];
  }

  getCurrentSubModelProblemType() {
    const subModel = this._subModelsById[this._selectedSubModelId];
    return subModel ? subModel.ProblemType : null;
  }

  _formatFloat(value) {
    if (value) {
      try {
        value = Math.round(value * 100) / 100;
      } catch {}
    }
    return value;
  }

  isTargetOptionsAvailable() {
    return !this.isPredictMode() && this._target?.level?.length > 0;
  }

  // isTargetDateTime() {
  //   return this._target?.level?.length > 0;
  // }

  setValue(featureName, value) {
    this._values[featureName] = value;

    // Notify view
    this._setValues({ ...this._values });
  }

  setTargetValue(value) {
    this._targetValue = value;

    // Notify view
    this._setTargetValue(value);
  }

  getTargetValue() {
    return this._targetValue;
  }

  getValue(featureName) {
    let def = false;
    let value = this._values[featureName];
    if (!value) {
      value = this._defaultValues[featureName];
      def = true;
    }

    if (value) {
      return {
        isDefault: def,
        value: value,
      };
    }
    return null;
  }

  getFeatures() {
    if (!this._featuresSorted) return [];
    return this._featuresSorted;
  }

  getTargetName() {
    if (this?._target?.name) {
      return this._target.name;
    }
    return null;
  }

  getPredictionDistribution() {
    return this._predictionDistribution;
  }

  getPredictionResult() {
    let v = this._predictionResult;
    try {
      const f = this._formatFloat(parseFloat(v));
      v = isNaN(f) ? v : f;
    } catch (e) {}

    return v !== null ? "" + v : "";
  }

  getSelectedSubModelId() {
    return this._selectedSubModelId;
  }

  async onSelectSubModel(id, isSwitchModes) {
    this.showStatus(null);
    try {
      const values = await SUB_MODEL_SERVICE.getValues(id);
      console.log("## VALUES:");
      console.log(values);

      // HACK: Timeseries bug when in optimal mode
      if (!isSwitchModes) {
        await this.switchModes(true, true);
      }

      const isPredict = this.isPredictMode();

      this._selectedSubModelId = id;
      this._features = values.columns ? values.columns : {};
      this._target = values.target ? values.target : null;
      this._targetValue = null;
      this._defaultValues = {};
      this._values = {};
      this._featuresSorted = [];

      // Features
      for (let f in this._features) {
        const feat = this._features[f];
        feat.name = f;

        // sorted
        this._featuresSorted.push(feat);
        // values
        this._defaultValues[f] = isPredict ? "" + feat.value : "Not available"; // TODO: i18n
      }

      this._featuresSorted.sort((a, b) => {
        const pmA = a.importance;
        const pmB = b.importance;
        return pmB - pmA;
      });

      // Get prediction
      if (isPredict) {
        await this.predict();
      } else {
        if (this._target?.level?.length > 0) {
          this._targetValue = "" + this._target.level[0];
          await this.optimal();
        }
      }

      await this._calcTimeSeriesDistribution();

      // Update view
      this._setSelectedSubModel(this._selectedSubModelId);
      this._setFeatures(this._features);
      this._setTarget(this._target);
      this._setTargetValue(this._targetValue);
      this._setPredictionResult(this._predictionResult);
      this._setValues(this._values);
    } finally {
      this.hideStatus();
    }
  }

  async getColumnInfo(columnName) {
    if (!this._modelConfig) {
      let modelId = null;
      try {
        modelId = this._model.ID;
      } catch (e) {
        LOGGER.info(e);
      }

      if (!modelId) {
        return null;
      }

      try {
        this._modelConfig = await MODEL.getModelConfiguration(modelId);
      } catch (e) {
        this._modelConfig = EMPTY_CONFIG;
        LOGGER.error("Error retrieving column info", e);
        return null;
      }
    }

    if (this._modelConfig === EMPTY_CONFIG) {
      return null;
    }

    return this._modelConfig.getColumns().getColumn(columnName);
  }

  getPredictionCount() {
    return this._predictionCount;
  }

  async predict() {
    this.showStatus(null);
    try {
      if (!this._features) throw Error("No features available.");
      if (!this._selectedSubModelId)
        throw Error("A sub model has not been selected.");

      const values = {};
      for (let n in this._features) {
        let f = this._features[n];
        let v = this.getValue(n).value;

        if (f.type.startsWith("numeric")) {
          try {
            const f = parseFloat(v);
            v = isNaN(f) ? v : f;
          } catch {}
        }

        values[n] = v;
      }

      console.log("## PREDICT VALUES:");
      console.log(values);

      const result = await SUB_MODEL_SERVICE.predict(
        this._selectedSubModelId,
        values
      );
      console.log("## RESULT");
      console.log(result);
      if (result.results !== null) {
        this._predictionResult = "" + result.results;
        this._predictionDistribution = result?.tsDistribution;
        this._predictionCount++;

        // Notify view
        this._setPredictionCount(this._predictionCount);
        this._setPredictionResult(this._predictionResult);
      }
    } finally {
      this.hideStatus();
    }
  }

  async optimal() {
    this.showStatus(null);
    try {
      if (!this._targetValue) throw Error("No target value specified.");
      if (!this._selectedSubModelId)
        throw Error("A sub model has not been selected.");

      const result = await SUB_MODEL_SERVICE.optimal(
        this._selectedSubModelId,
        this._targetValue
      ); // token

      console.log("## OPTIMAL VALUES:");
      console.log(result);

      // Values
      for (let f in result) {
        this._defaultValues[f] = "" + result[f].value;
      }

      // Notify
      // TODO: This is a hack, because we are only setting default values
      this._setValues({});
    } finally {
      this.hideStatus();
    }
  }

  async onModelLoaded(model) {
    await super.onModelLoaded(model);
    await this._calcTimeSeriesDistribution();
  }

  _reset() {
    super._reset();
    this._modelConfig = null;
    this._selectedSubModelId = null;
    this._predictMode = true;
    this._predictionCount = 0;

    this._features = null;
    this._featuresSorted = [];
    this._defaultValues = null;
    this._values = null;
    this._target = null;
    this._targetValue = null;
    this._timeSeriesDistribution = null;
    this._predictionResult = null;
    this._predictionDistribution = null;
  }
}

// Singleton
const VIEW_MODEL = new ViewModel();

export default VIEW_MODEL;
