import React from "react";

import { Grid } from "@mui/material";
import { FormattedMessage } from "react-intl";
import TimeSeriesChart from "_machina/react/dialogs/dataset-wizard-dialog/common/TimeSeriesChart";

import MainCard from "ui-component/cards/MainCard";

const ModelTimeSeriesChart = ({ distribution, overrides }) => {
  return (
    <MainCard title={<FormattedMessage id="modelTimeSeriesChart" />}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TimeSeriesChart
            distribution={distribution}
            overridesModel={overrides}
            onlyChart={true}
            height={350}
          />
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default ModelTimeSeriesChart;
