import BaseModel from "./BaseModel";
import APP from "./App";
import AUTH_SERVICE from "_machina/service/AuthService";
import CURRENT_USER from "./CurrentUser";

class AuthModel extends BaseModel {
  ROLE_ID_USER = "role.user";
  ROLE_ID_ADMIN = "role.admin";
  PERM_ID_VIEW_ADMIN_SETTINGS = "perm.view.adminSettings";

  init(statusCb) {
    super.init(statusCb);
    this._roles = {};
  }

  async loadRoles() {
    console.log("LOAD ROLES");
    this._roles = {};
    const response = await AUTH_SERVICE.getRoles();
    console.log(response);
    for (let i = 0; i < response.length; i++) {
      const r = response[i];
      const perms = {};
      this._roles[r.RoleID] = perms;
      for (let j = 0; j < r.Permissions.length; j++) {
        const p = r.Permissions[j];
        perms[p.PermissionID] = p.PermissionID;
      }
    }
    APP.refresh();
  }

  getRoles() {
    return this._roles;
  }

  hasPermission(permissionId) {
    const r = this._roles ? this._roles[CURRENT_USER.getRole()] : null;
    if (r) {
      return r[permissionId] ? true : false;
    }
    return false;
  }
}

// Singleton
const AUTH_MODEL = new AuthModel();

export default AUTH_MODEL;
