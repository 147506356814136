import * as React from "react";

import { Menu } from "@mui/material";

export default function CommonMenu({
  anchorEl,
  anchorOrigin,
  transformOrigin,
  onClose,
  children,
}) {
  return (
    <Menu
      keepMounted
      variant="selectedMenu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={anchorOrigin || { vertical: "top", horizontal: "center" }}
      transformOrigin={
        transformOrigin || { vertical: "top", horizontal: "left" }
      }
    >
      {children}
    </Menu>
  );
}
