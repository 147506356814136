import { useState } from "react";

import { styled, useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import MenuIcon from "@mui/icons-material/ListOutlined";
import { FormattedMessage } from "react-intl";

import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";

import { LOGGER } from "_machina/util/Logging";
import STRING_UTIL from "_machina/util/StringUtil";
import APP from "_machina/react/model/App";
import VIEW_MODEL from "_machina/react/model/ViewModel";
import colorAssigner from "_machina/util/ColorAssigner";
import CommonMenu from "_machina/react/components/common/CommonMenu";
import EditFeatureButton from "./EditFeatureButton";
import CommonTooltip from "_machina/react/components/common/CommonTooltip";

// styles
const CardWrapper = styled(MainCard)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.primary.light,
  overflow: "hidden",
  position: "relative",
  "&:after": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    background: `linear-gradient(210.04deg, ${theme.palette.primary[200]} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
    borderRadius: "50%",
    top: -30,
    right: -180,
  },
  "&:before": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    background: `linear-gradient(140.9deg, ${theme.palette.primary[200]} -14.02%, rgba(144, 202, 249, 0) 77.58%)`,
    borderRadius: "50%",
    top: -160,
    right: -130,
  },
}));

const TargetComponent = ({ isTimeSeries }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [subModels, setSubModels] = useState(null);

  const handleMenuClick = (event) => {
    event.stopPropagation();
    const sm = VIEW_MODEL.getSubModels();
    if (sm.length > 0) {
      setMenuAnchorEl(event.currentTarget);
      setSubModels(sm);
    }
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const theme = useTheme();
  const isPredict = VIEW_MODEL.isPredictMode();
  const targetOnClick = !VIEW_MODEL.isTargetOptionsAvailable()
    ? null
    : () => {
        VIEW_MODEL.openTargetEditor();
      };

  let value = null;
  if (isPredict) {
    value = VIEW_MODEL.getPredictionResult();
  } else {
    value = VIEW_MODEL.getTargetValue();
    // Remove duped dashes (0.0 - 0.0) = 0.0, etc..
    value = STRING_UTIL.removeDupedDashes(value);
  }

  return (
    <>
      <CardWrapper
        border={false}
        content={false}
        sx={targetOnClick !== null ? { cursor: "pointer" } : {}}
        onClick={targetOnClick}
      >
        <CommonTooltip
          title={VIEW_MODEL.getOverrides().getColumnDescription(
            VIEW_MODEL.getTargetName()
          )}
          placement="bottom"
          arrow
        >
          <Box sx={{ px: 2, py: 0.7 }}>
            <List sx={{ p: 0, m: 0 }}>
              <ListItem alignItems="center" disableGutters>
                <ListItemAvatar>
                  <Avatar
                    onClick={(e) => {
                      handleMenuClick(e);
                    }}
                    variant="rounded"
                    sx={{
                      ...theme.typography.commonAvatar,
                      ...theme.typography.largeAvatar,
                      backgroundColor: theme.palette.primary[800],
                      color: "#fff",
                      "&:hover": {
                        /* TODO: Something better than this... */
                        opacity: 0.7,
                      },
                    }}
                  >
                    <MenuIcon fontSize="inherit" />
                  </Avatar>
                </ListItemAvatar>
                <div
                  key={
                    VIEW_MODEL.getPredictionCount() +
                    "-" +
                    VIEW_MODEL.getSelectedSubModelId() +
                    "-" +
                    VIEW_MODEL.getPredictionResult() +
                    "-" +
                    VIEW_MODEL.getTargetName()
                  }
                  style={{ width: "100%" }}
                >
                  <Transitions type={"fade"} in={true} direction={"left"}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {!isTimeSeries ? (
                        <ListItemText
                          sx={{ p: 0, m: 0 }}
                          primary={
                            <Typography
                              variant="h4"
                              sx={{
                                color: "#fff",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {VIEW_MODEL.getOverrides().getColumnValueAlias(
                                VIEW_MODEL.getTargetName(),
                                value
                              )}
                            </Typography>
                          }
                          secondary={
                            <Typography
                              variant="subtitle2"
                              sx={{
                                color: "primary.light",
                                mt: 0.25,
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {VIEW_MODEL.getOverrides().getColumnAlias(
                                VIEW_MODEL.getTargetName()
                              )}
                            </Typography>
                          }
                        />
                      ) : (
                        <ListItemText
                          sx={{ p: 0, m: 0 }}
                          primary={
                            <Typography
                              variant="h3"
                              sx={{
                                color: "#fff",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {VIEW_MODEL.getOverrides().getColumnAlias(
                                VIEW_MODEL.getTargetName()
                              )}
                            </Typography>
                          }
                        />
                      )}
                      {targetOnClick && (
                        <EditFeatureButton
                          isTarget={true}
                          featureName={VIEW_MODEL.getTargetName()}
                        />
                      )}
                    </Stack>
                  </Transitions>
                </div>
              </ListItem>
            </List>
          </Box>
        </CommonTooltip>
      </CardWrapper>
      {menuAnchorEl && (
        <CommonMenu
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          anchorEl={menuAnchorEl}
          onClose={closeMenu}
        >
          {subModels.map((sm) => {
            const color = colorAssigner(sm.PrimaryMetric);
            // console.log(color)
            return (
              <MenuItem
                key={sm.ID}
                onClick={() => {
                  closeMenu();
                  (async () => {
                    try {
                      await VIEW_MODEL.selectSubModel(sm.ID);
                    } catch (e) {
                      LOGGER.error("Error loading model", e);
                      APP.showErrorMessage(
                        <FormattedMessage id="error.model.loading" />
                      );
                    }
                  })();
                }}
              >
                <CircleIcon sx={{ width: "16px", mr: 0.7, color: color }} />{" "}
                {VIEW_MODEL.getOverrides().getColumnAlias(sm.ColumnName)}
              </MenuItem>
            );
          })}
        </CommonMenu>
      )}
    </>
  );
};

export default TargetComponent;
