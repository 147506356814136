import React, { useEffect } from "react";

import { useTheme } from "@mui/system";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import {
  Grid,
  Box,
  Tabs,
  Tab,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";

import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import GetAppRoundedIcon from "@mui/icons-material/GetAppRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";

import CommonButton from "_machina/react/components/common/fields/CommonButton";
import SearchIcon from "@mui/icons-material/Search";
import MainCard from "ui-component/cards/MainCard";

import { LOGGER } from "_machina/util/Logging";
import APP from "_machina/react/model/App";
import SCENARIOS_MODEL from "_machina/react/model/ScenariosModel";
import Configuration from "./configuration";
import Analysis from "./analysis";
import ScenarioCard from "./ScenarioCard";
import StatusComponent from "../view-model-page/StatusComponent";
// import TitleComponent from "./TitleComponent";
// import TitleSkeleton from "../view-model-page/skeletons/TitleSkeleton";
import RectangularSkeleton from "_machina/react/components/common/skeleton/RectangularSkeleton";

import TargetDialog, { openTargetDialog } from "./dialogs/TargetDialog";

export default function ScenariosPage() {
  const [model, setModel] = React.useState(null);
  const [, setSubModels] = React.useState(null);
  const [, setModelStatus] = React.useState(null);
  const [updateCount, setUpdateCount] = React.useState(0);
  const { id } = useParams();

  const [ran, setRan] = React.useState(false);
  const [tab, setTab] = React.useState(0);

  const theme = useTheme();

  SCENARIOS_MODEL._setModel = setModel;
  SCENARIOS_MODEL._setSubModels = setSubModels;
  SCENARIOS_MODEL._setModelStatus = setModelStatus;

  const status = SCENARIOS_MODEL.getStatus();

  const forceUpdate = () => {
    setUpdateCount(updateCount + 1);
  };
  SCENARIOS_MODEL._forceUpdate = forceUpdate;

  const render = model !== null && SCENARIOS_MODEL.isRenderable();

  useEffect(() => {
    return () => {
      SCENARIOS_MODEL.stopTimer();
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await SCENARIOS_MODEL.loadModel(id);
      } catch (e) {
        LOGGER.error("Error loading model", e);
        APP.showErrorMessage(<FormattedMessage id="error.model.loading" />);
      }
    })();
  }, [id]);

  const statusComponent = SCENARIOS_MODEL.isStatusShown() && model !== null && (
    <Grid item xs={12} sm={12}>
      <StatusComponent model={model} status={status} />
    </Grid>
  );

  if (!render)
    return (
      <Grid container spacing={2}>
        {statusComponent}
        {/* <Grid item xs={12} sm={8}>
          {model ? <TitleComponent model={model} /> : <TitleSkeleton />}
        </Grid>
        <Grid item xs={12} sm={4}>
          <TitleSkeleton />
        </Grid> */}
        <Grid item xs={12}>
          <RectangularSkeleton height={60} />
        </Grid>
        <Grid item xs={12}>
          <RectangularSkeleton height={80} />
        </Grid>
        <Grid item xs={12}>
          <RectangularSkeleton height={200} />
        </Grid>
        <Grid item xs={12}>
          <RectangularSkeleton height={400} />
        </Grid>
      </Grid>
    );

  return (
    <>
      <Grid container spacing={3} sx={{ pb: 3 }}>
        {statusComponent}
      </Grid>
      <MainCard
        title={
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
          >
            <Grid item>
              <Typography variant="h3">Scenarios</Typography>
            </Grid>
            <Grid item>
              <Grid container spacing={1.5} alignItems="center">
                <Grid item>
                  <CommonButton startIcon={<GetAppRoundedIcon />}>
                    Load...
                  </CommonButton>
                </Grid>
                <Grid item>
                  <CommonButton startIcon={<AddRoundedIcon />}>
                    New
                  </CommonButton>
                </Grid>
                <Grid item>
                  <CommonButton startIcon={<SaveRoundedIcon />}>
                    Save
                  </CommonButton>
                </Grid>
                <Grid item>
                  <CommonButton startIcon={<DeleteForeverRoundedIcon />}>
                    Delete
                  </CommonButton>
                </Grid>
                <Grid item>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    // onChange={handleSearch}
                    placeholder={"Search Scenarios"}
                    value={""}
                    size="small"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Box sx={{ display: "flex" }}>
              <Grid container>
                <Grid item xs={12}>
                  <ScenarioCard
                    model={model}
                    // primary="Stroke Data Scenario"
                    // secondary="Identify characteristics of individuals most likely to leave."
                    color={theme.palette.secondary.main}
                  />

                  <Box sx={{ mt: 2, px: 1, pb: 2 }}>
                    <Tabs value={tab} variant="scrollable">
                      <Tab
                        sx={{ textTransform: "none" }}
                        onClick={() => {
                          setTab(0);
                        }}
                        label={"Configuration"}
                      />
                      {ran && (
                        <Tab
                          sx={{ textTransform: "none" }}
                          onClick={() => {
                            setTab(1);
                          }}
                          label={"Analysis"}
                        />
                      )}
                      <>
                        <Box
                          sx={{
                            paddingBottom: "16px",
                            paddingRight: "12px",
                            textAlign: "right",
                            width: "100%",
                          }}
                        >
                          <CommonButton
                            onClick={() => {
                              APP.showBusyScreen(true);
                              setTimeout(
                                () => {
                                  APP.hideBusyScreen(true);
                                  setRan(true);
                                  setTab(1);
                                },
                                // 0
                                4000
                              );
                            }}
                            sx={{
                              visibility: tab === 0 ? "visible" : "hidden",
                            }}
                            color="success"
                            startIcon={<PlayArrowRoundedIcon />}
                          >
                            Run
                          </CommonButton>
                        </Box>
                      </>
                    </Tabs>
                  </Box>

                  {tab === 0 && (
                    <Configuration openTargetDialog={openTargetDialog} />
                  )}
                  {tab === 1 && <Analysis />}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </MainCard>
      {/* </Grid> */}
      <TargetDialog />
    </>
  );
}
