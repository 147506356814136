import { FIREBASE_API } from "config";
import firebase from "firebase/compat/app";

import { StatusCallback } from "./react/model/BaseModel";

import { LOGGER } from "./util/Logging";
import CONFIG from "./config/Config";
import APP from "./react/model/App";
import CURRENT_USER from "./react/model/CurrentUser";
import DATASET from "./react/model/Dataset";
import MODEL from "./react/model/Model";
import MODEL_LIST from "./react/model/ModelList";
import USER from "./react/model/User";
import USER_LIST from "./react/model/UserList";
import VIEW_MODEL from "./react/model/ViewModel";
import WORKFLOW from "./react/model/Workflow";
import AUTH_MODEL from "./react/model/AuthModel";
import SCENARIOS_MODEL from "./react/model/ScenariosModel";

import PREFS from "./Prefs";

async function pullConfigFile() {
  // Pull config file
  // let path = window.location.href;
  // const CONSOLE = "/console";
  // let consoleIdx = path.indexOf(CONSOLE);
  // if (consoleIdx !== -1) {
  //   path = path.substring(0, consoleIdx + CONSOLE.length);
  // } else {
  //   path = window.location.origin;
  // }
  // if (path.endsWith("/")) {
  //   path = path.substring(0, path.length - 1);
  // }
  const path = window.location.origin;
  const response = await fetch(`${path}/config.json`);
  const status = response.status;
  let text = "";
  try {
    text = await response.text();
  } catch (e) {
    console.error(e);
  }
  if (status >= 200 && status < 300) {
    const config = JSON.parse(text);
    if (config.loggingLevel) {
      LOGGER.setLevel(config.loggingLevel);
    }
    if (config.baseUrl) {
      CONFIG.setBaseUrl(config.baseUrl);
    } else {
      throw Error("Unable to find BASE URL in configuration.");
    }
    if (config?.firebaseConfig?.apiKey) {
      FIREBASE_API.apiKey = `${config.firebaseConfig.apiKey}`;
    } else {
      throw Error("Unable to find API Key in configuration.");
    }
    if (config?.firebaseConfig?.authDomain) {
      FIREBASE_API.authDomain = config.firebaseConfig.authDomain;
    } else {
      throw Error("Unable to find Auth Domain in configuration.");
    }

    // conditionally set tenant
    firebase.initializeApp(FIREBASE_API);
    let tenant = config.tenant;
    if (tenant && tenant.length > 0) {
      firebase.auth().tenantId = tenant;
    }
  } else {
    throw new Error(`${status}: ${text}`);
  }
}

export default async function initMachina() {
  PREFS.load();

  const statusCallback = new StatusCallback();
  statusCallback.onShow = (message, delay) => {
    APP.showBusyScreen(message, delay);
  };
  statusCallback.onHide = () => {
    APP.hideBusyScreen();
  };

  // Init services
  APP.init(statusCallback);
  DATASET.init(statusCallback);
  CURRENT_USER.init(statusCallback);
  MODEL.init(statusCallback);
  MODEL_LIST.init(statusCallback);
  USER.init(statusCallback);
  USER_LIST.init(statusCallback);
  VIEW_MODEL.init(statusCallback);
  WORKFLOW.init(statusCallback);
  AUTH_MODEL.init(statusCallback);
  SCENARIOS_MODEL.init(statusCallback);

  await pullConfigFile();
  await AUTH_MODEL.loadRoles();

  return true;
}
