import React from "react";

// material-ui
import { Button } from "@mui/material";

export default function CommonButton({ children, ...other }) {
  return (
    <Button variant="outlined" {...other}>
      {children}
    </Button>
  );
}
