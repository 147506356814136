import { LOGGER } from "_machina/util/Logging";
import BaseModel from "./BaseModel";
import MODEL_SERVICE from "_machina/service/ModelService";
import SUB_MODEL_SERVICE from "_machina/service/SubModelService";
import DATASET from "./Dataset";

import DatasetOverridesModel from "../dialogs/dataset-wizard-dialog/DatasetOverridesModel";

export const StatusPreTraining = 0;
export const StatusTraining = 5;
export const StatusCompleted = 50;
export const StatusErrored = 100;

export default class BaseViewModel extends BaseModel {
  /**
   * @constructor
   */
  constructor() {
    super();

    this._setModelStatus = null;
    this._setModel = null;
    this._setSubModels = null;

    this._reset();
  }

  /**
   * Initializes the model
   * @param statusCb The optional status callback
   */
  init(statusCb) {
    super.init(statusCb);
  }

  getOverrides() {
    return this._datasetOverridesModel;
  }

  getSubModels() {
    if (!this._subModels) return [];

    const clone = [...this._subModels];
    clone.sort((a, b) => {
      const pmA = a.PrimaryMetric;
      const pmB = b.PrimaryMetric;
      return pmB - pmA;
    });
    return clone;
  }

  getStatus() {
    return this._modelStatus;
  }

  isRenderable() {
    return (
      this._modelStatus &&
      this._modelStatus.status > StatusPreTraining &&
      this._modelStatus.steps > 0
    );
  }

  isStatusShown() {
    if (!this._modelStatus) return true;

    return !(
      this._modelStatus.status === StatusCompleted &&
      this._modelStatus.trainingUpdate === this._modelStatus.startTime
    );
  }

  _updateModelStatus(model) {
    const status = {
      errorMessage: model.ErrorMessage,
      status: model.Status,
      trainingUpdate: model.TrainingUpdate,
      steps: model.Steps,
      targetSteps: model.TargetSteps,
    };

    if (this._modelStatus === null) {
      status.startTime = status.trainingUpdate;
      status.startStatus = status.status;
    } else {
      status.startTime = this._modelStatus.startTime;
      status.startStatus = this._modelStatus.startStatus;
    }

    if (
      this.modelStatus === null ||
      JSON.stringify(status) !== JSON.stringify(this._modelStatus)
    ) {
      console.log("changed.");
      this._modelStatus = status;

      // notify view
      this._setModelStatus(status);
    } else {
      console.log("not changed.");
    }

    if (status.status < StatusCompleted) {
      this._timerId = setTimeout(async () => {
        let succeeded = false;
        try {
          const updatedModel = await MODEL_SERVICE.get(model.ID);
          if (
            status.startStatus < StatusTraining &&
            updatedModel.Status >= StatusTraining
          ) {
            console.log("Reloading model.");
            setTimeout(() => this.loadModel(model.ID), 0);
          } else {
            this._updateModelStatus(updatedModel);
          }
          succeeded = true;
        } catch (e) {
          LOGGER.error("Error updating status", e);
        } finally {
          // reschedule on error
          if (!succeeded) {
            this._updateModelStatus(model);
          }
        }
      }, 10 * 1000 /* TODO: Make variable, setting, etc. */);
    }
  }

  async _loadSubModels(id, selectPrimary = false) {
    this.showStatus();
    try {
      // Load sub-models
      // TODO: What to do if there are no submodels?
      const subModels = await SUB_MODEL_SERVICE.findByModel(id);

      console.log("### SUB MODELS:");
      console.log(subModels);

      // Determine current sub-model (based on primary metric)
      let maxMetric = -65536;
      let maxId = -1;
      const subModelsById = {};
      for (let i = 0; i < subModels.length; i++) {
        const s = subModels[i];
        subModelsById[s.ID] = s;
        if (s.PrimaryMetric > maxMetric) {
          maxMetric = s.PrimaryMetric;
          maxId = s.ID;
        }
      }

      this._subModels = subModels;
      this._subModelsById = subModelsById;

      // Load sub-model values
      if (selectPrimary && maxId >= 0) {
        await this.onSelectSubModel(maxId);
      }

      // override in derived
      await this.onLoadSubModels(this._subModels);
    } finally {
      this.hideStatus();
    }
  }

  async selectSubModel(modelId) {
    this.onSelectSubModel(modelId);
  }

  async loadModel(id) {
    this.showStatus();
    try {
      // Reset
      this._reset();

      // Load model
      const model = await MODEL_SERVICE.get(id);

      // Load sub-models
      // TODO: What to do if there are no submodels?
      try {
        await this._loadSubModels(id, true);
      } catch (e) {
        // This is a hack to handle errors that occur when there is a latency between a
        // submodel existing in the database and the files being written to cloud storage
        // TODO: Spend more time to find a proper solution
        LOGGER.error("Error reading sub-models", e);
      }

      // Attempt to load overrides
      this._datasetOverridesModel = new DatasetOverridesModel({});
      try {
        const overrides = await DATASET.getOverrides(model.DatasetID);
        this._datasetOverridesModel = new DatasetOverridesModel({
          datasetOverrides: overrides,
        });
      } catch (e) {
        LOGGER.error("Error reading overrides", e);
      }

      this._model = model;

      // Override in derived
      await this.onModelLoaded(model);
    } finally {
      this.hideStatus();
    }
  }

  async onLoadSubModels(subModels) {
    this._setSubModels(subModels);
  }

  async onModelLoaded(model) {
    // Update view
    this._setModel(model);
    this._updateModelStatus(model);
  }

  stopTimer() {
    if (this._timerId !== null) {
      console.log("stopping timer.");
      clearTimeout(this._timerId);
      this._timerId = null;
    }
  }

  _reset() {
    this.stopTimer();
    this._model = null;
    this._modelStatus = null;
    this._subModels = null;
    this._subModelsById = {};
    this._datasetOverridesModel = null;
    this._timerId = null;
  }
}
