import BaseService from "./BaseService";
import CURRENT_USER from "_machina/react/model/CurrentUser";

/**
 * Service used to access datasets
 */
class DatasetService extends BaseService {
  async getList(
    page = 0,
    pageSize = 25,
    sortColumn = null,
    sortAscending = true
  ) {
    let url = `/api/dataset/list?page=${page}&pageSize=${pageSize}`;
    if (sortColumn) {
      url = `${url}&sortColumn=${sortColumn}&sortAscending=${sortAscending}`;
    }
    // console.log(url)
    return await this._fetchGet(await CURRENT_USER.getAuthToken(), url);
  }

  async get(datasetId) {
    const url = `/api/dataset/${datasetId}`;
    return await this._fetchGet(await CURRENT_USER.getAuthToken(), url);
  }

  async getOverrides(datasetId) {
    const url = `/api/dataset/${datasetId}/overrides`;
    return await this._fetchGet(await CURRENT_USER.getAuthToken(), url);
  }

  async delete(datasetId) {
    const url = `/api/dataset/${datasetId}`;
    return await this._fetchDelete(await CURRENT_USER.getAuthToken(), url);
  }

  async update(dataset) {
    const url = `/api/dataset/${dataset.ID}`;
    return await this._fetchPut(
      await CURRENT_USER.getAuthToken(),
      url,
      dataset
    );
  }

  async upload(file) {
    const url = `/api/dataset/upload`;
    const formData = new FormData();
    formData.append("file", file);
    return await this._fetchPost(
      await CURRENT_USER.getAuthToken(),
      url,
      formData,
      false,
      null
    );
  }

  async analyze(fileUri, columns, excludedColumns) {
    const url = `/api/dataset/analyze`;
    return await this._fetchPost(
      await CURRENT_USER.getAuthToken(),
      url,
      {
        fileURI: fileUri,
        columns: columns,
        excludedColumns: excludedColumns,
      },
      true
    );
  }

  async preview(fileUri, rows = 100) {
    const url = `/api/dataset/preview`;
    return await this._fetchPost(
      await CURRENT_USER.getAuthToken(),
      url,
      {
        fileURI: fileUri,
        rows: rows,
      },
      true
    );
  }

  async uniqueValues(fileUri, column, rows = 100) {
    const url = `/api/dataset/unique-values`;
    return await this._fetchPost(
      await CURRENT_USER.getAuthToken(),
      url,
      {
        fileURI: fileUri,
        column: column,
        rows: rows,
      },
      true
    );
  }
}

// Singleton
const DATASET_SERVICE = new DatasetService();

export default DATASET_SERVICE;
