import React from "react";
import { useTheme } from "@mui/material/styles";
import { Grid, Typography, Stack, Chip, Box } from "@mui/material";
import MainCard from "ui-component/cards/MainCard";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import SCENARIOS_MODEL from "_machina/react/model/ScenariosModel";
import { getNameForModelType } from "_machina/react/dialogs/dataset-wizard-dialog/FileAnalysisModel";

const Target = ({ divSx }) => {
  const theme = useTheme();
  return (
    <div>
      <MainCard
        contentSX={{ m: 0, my: 2, mx: 0.5, p: 0, px: 2 }}
        sx={{
          backgroundColor:
            theme.palette.mode === "dark"
              ? theme.palette.dark.light + 10
              : theme.palette.grey[200],
          borderColor:
            theme.palette.mode === "dark"
              ? theme.palette.dark.light + 15
              : theme.palette.grey[200],
          ...divSx,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div>
              <Typography fontSize="1.3em">
                {SCENARIOS_MODEL.getOverrides().getColumnAlias(
                  SCENARIOS_MODEL.getSelectedSubModelColumnName()
                )}
              </Typography>
              <Stack
                direction="row"
                spacing={0.5}
                alignItems="center"
                sx={{ marginTop: "5px" }}
              >
                <FormatListBulletedRoundedIcon
                  sx={{ fontSize: "1.4em", color: "#0883e6" }}
                />
                <Typography
                  sx={{ paddingTop: "1px", color: "#0883e6" }}
                  fontSize={"13px"}
                >
                  {getNameForModelType(
                    SCENARIOS_MODEL.getSelectedSubModelProblemType()
                  )}
                </Typography>
              </Stack>
            </div>
          </Grid>
        </Grid>
        <Box
          sx={{
            border: "2px solid #3a496b",
            backgroundColor: "#41527c",
            padding: "15px",
            marginTop: "15px",
            borderRadius: 2,
          }}
        >
          {SCENARIOS_MODEL.getFormattedTargetValues().map(function (
            value,
            index
          ) {
            return (
              <Chip
                key={value}
                color="primary"
                sx={{ height: "26px", opacity: 0.9, mr: 1 }}
                label={value}
              />
            );
          })}
        </Box>
      </MainCard>
    </div>
  );
};

export default Target;
