/**
 * String-related utility methods
 */
class StringUtil {
  /**
   * Allows for string-based formatting (similar to printf, etc.)
   * <code>STRING_UTIL.format("foo {0} baz {1}", ["bar", "ugh"]);</code>
   * @param {string} str The format string
   * @param {Array} o Array of replacement values
   * @returns The formatted string
   */
  format(str, o) {
    return str.replace(/{([^{}]*)}/g, function (a, b) {
      const r = o[b];
      return typeof r === "string" ? r : a;
    });
  }

  stripExtension(str) {
    const index = str.lastIndexOf(".");
    if (index !== -1) {
      return str.substring(0, index);
    }
    return str;
  }

  isOnlyDates(dateStrings) {
    for (let i = 0; i < dateStrings.length; i++) {
      if (!dateStrings[i].endsWith("00:00:00")) {
        return false;
      }
    }
    return true;
  }

  stripTime(dateString) {
    if (dateString.endsWith("00:00:00")) {
      return dateString.substring(0, dateString.length - 8);
    }
  }

  // Remove duped dashes (0.0 - 0.0) = 0.0, etc..
  removeDupedDashes(str) {
    str = "" + str;
    if (str) {
      const vals = str.split(" - ");
      if (vals.length === 2) {
        const v1 = vals[0].trim();
        const v2 = vals[1].trim();
        if (v1.length > 0 && v1 === v2) {
          str = v1;
        }
      }
    }
    return str;
  }
}

// The singleton
const STRING_UTIL = new StringUtil();

export default STRING_UTIL;
