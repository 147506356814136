// import FIREBASE from '../../util/Firebase';
import BaseModel from "./BaseModel";

import { LOGGER } from "_machina/util/Logging";
import USER_SERVICE from "_machina/service/UserService";
import APP from "./App";

/**
 * Represents the currently logged in user
 */
class CurrentUser extends BaseModel {
  constructor() {
    super();
    this.auth = null;
    this.uid = null;
    this.currentUser = null;
    this._setCurrentUserCallback = null;
  }

  async updateCurrentUser() {
    if (!this.uid) return;
    try {
      this.currentUser = await USER_SERVICE.getByUid(this.uid);
      console.log(this.currentUser);
      if (this._setCurrentUserCallback) {
        this._setCurrentUserCallback(this.currentUser);
      }
    } catch (e) {
      LOGGER.error("Error retrieving current user", e);
    }

    // Refresh app if the current user is updated
    APP.refresh();

    return this.currentUser;
  }

  getUid() {
    return this.uid;
  }

  async getCurrentUser() {
    if (this.currentUser) return this.currentUser;
    return await this.updateCurrentUser();
  }

  /**
   * Initializes the model
   * @param statusCb The optional status callback
   */
  init(statusCb) {
    super.init(statusCb);
  }

  getRole() {
    return this?.currentUser?.Role;
  }

  setAuth(auth) {
    this.auth = auth;
    if (auth && auth.currentUser) {
      this.uid = auth.currentUser.uid;
      this.updateCurrentUser();
    }

    if (!auth) {
      this.uid = null;
      this.currentUser = null;
    }
  }

  async getAuthToken() {
    // console.log( this.auth.currentUser )
    return await this.auth.currentUser.getIdToken();
  }
}

// Singleton
const CURRENT_USER = new CurrentUser();

export default CURRENT_USER;
