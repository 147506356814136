import * as React from "react";

import { useIntl, FormattedMessage } from "react-intl";

import { TableCell } from "@mui/material";

import { openConfirmDialog } from "_machina/react/components/common/dialog/ConfirmDialog";
import { openUserWizard } from "_machina/react/dialogs/user-wizard-dialog";
import { LOGGER } from "_machina/util/Logging";
import APP from "_machina/react/model/App";
import USER from "_machina/react/model/User";
import USER_LIST from "_machina/react/model/UserList";
import CommonLabeledImage from "_machina/react/components/common/CommonLabeledImage";
import CommonPageHeader from "_machina/react/components/common/CommonPageHeader";
import CommonTable from "_machina/react/components/common/table/CommonTable";
import CommonTableEditColumn from "_machina/react/components/common/table/CommonTableEditColumn";
import DeleteButton from "_machina/react/components/common/toolbar/DeleteButton";
import TableSkeleton from "_machina/react/components/common/skeleton/TableSkeleton";
import LabeledImageSkeleton from "_machina/react/components/common/skeleton/LabeledImageSkeleton";
import TextSkeleton from "_machina/react/components/common/skeleton/TextSkeleton";
import TableIconButtonSkeleton from "_machina/react/components/common/skeleton/TableIconButtonSkeleton";
import IconButtonSkeleton from "_machina/react/components/common/skeleton/IconButtonSkeleton";

const TABLE_NAME = "users-table";

const usersTableHeadCells = [
  {
    id: "name",
    numeric: false,
    label: <FormattedMessage id="user" />,
    align: "left",
  },
  {
    id: "role",
    numeric: false,
    label: <FormattedMessage id="role" />,
    align: "left",
  },
  {
    id: "edit",
    numeric: false,
    label: <FormattedMessage id="edit" />,
    align: "center",
    disableSort: true,
  },
];

const UsersList = () => {
  const [results, setResults] = React.useState(null);

  USER_LIST._setResults = setResults;

  const updateResultsCallback = async (
    page,
    rowsPerPage,
    orderBy,
    orderAscending
  ) => {
    try {
      setTimeout(
        async () => {
          USER_LIST.setPageAndSort(page, rowsPerPage, orderBy, orderAscending);
          await USER_LIST.getUsers();
        },
        results === null ? 500 : 0
      );
    } catch (e) {
      LOGGER.error("Error retrieving users", e);
      APP.showErrorMessage(<FormattedMessage id="error.users.retrieving" />);
    }
  };

  return results === null ? (
    <TableSkeleton
      tableName={TABLE_NAME}
      cardMode={false}
      headCells={usersTableHeadCells}
      updateResultsCallback={updateResultsCallback}
      renderToolbarItems={() => {
        return <IconButtonSkeleton />;
      }}
      renderRow={() => {
        return (
          <>
            <TableCell>
              <LabeledImageSkeleton hasSubTitle={true} />
            </TableCell>
            <TableCell>
              <TextSkeleton sx={{ width: 100 }} />
            </TableCell>
            <TableCell align="center" sx={{ pr: 3 }}>
              <TableIconButtonSkeleton />
            </TableCell>
          </>
        );
      }}
      rowCount={5}
    />
  ) : (
    <CommonTable
      tableName={TABLE_NAME}
      cardView={false}
      headCells={usersTableHeadCells}
      updateResultsCallback={updateResultsCallback}
      results={results}
      renderToolbarItems={(selected) => {
        return (
          <>
            <DeleteButton
              disabled={!selected.length}
              onClick={() => {
                openConfirmDialog(
                  <FormattedMessage
                    id={
                      selected.length > 1
                        ? "delete.users.title"
                        : "delete.user.title"
                    }
                  />,
                  <FormattedMessage
                    id={
                      selected.length > 1
                        ? "delete.users.message"
                        : "delete.user.message"
                    }
                  />,
                  async () => {
                    try {
                      await USER_LIST.deleteUsers(selected);
                    } catch (e) {
                      LOGGER.error("Error deleting users", e);
                      APP.showErrorMessage(
                        <FormattedMessage id="error.users.deleting" />
                      );
                    }
                  }
                );
              }}
            />
          </>
        );
      }}
      renderRow={(row, index) => {
        return (
          <>
            <TableCell>
              <CommonLabeledImage
                avatarMode={true}
                imageUrl={row.ImageURL}
                title={row.Name}
                subTitle={row.Email}
              />
            </TableCell>
            <TableCell>
              <FormattedMessage id={row.Role} />
            </TableCell>
            <TableCell align="center" sx={{ pr: 3 }}>
              <CommonTableEditColumn
                onClick={async () => {
                  try {
                    await USER.showUserEditDialog(row.ID, (ok) => {
                      if (ok) USER_LIST.getUsers();
                    });
                  } catch (e) {
                    LOGGER.error("Error loading user", e);
                    APP.showErrorMessage(
                      <FormattedMessage id="error.user.retrieve" />
                    );
                  }
                }}
              />
            </TableCell>
          </>
        );
      }}
    />
  );
};

const UsersPage = () => {
  const intl = useIntl();
  return (
    <CommonPageHeader
      createText={intl.formatMessage({ id: "createUserDots" })}
      onCreate={() => openUserWizard()}
      title={intl.formatMessage({ id: "users" })}
      search={true}
      searchPlaceholder={intl.formatMessage({ id: "searchUsers" })}
    >
      <UsersList />
    </CommonPageHeader>
  );
};

export default UsersPage;
