import React from "react";

import { Box, Grid } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { FormattedMessage } from "react-intl";

import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

import { LOGGER } from "_machina/util/Logging";
import { uuidv4 } from "_machina/util/Uuid";
import { BaseDialogInner, SelectType } from "./BaseDialog";
import APP from "_machina/react/model/App";
import VIEW_MODEL from "_machina/react/model/ViewModel";
import CommonTextField from "_machina/react/components/common/fields/CommonTextField";
import CommonDateTimeField, {
  formatDateIn,
  formatDateOut,
} from "_machina/react/components/common/fields/CommonDateTimeField";
import DistributionChart from "_machina/react/dialogs/dataset-wizard-dialog/common/DistributionChart";
import CommonStatusComponent from "_machina/react/dialogs/dataset-wizard-dialog/common/CommonStatusComponent";

let _setUniqueId = null;
let _setFeature = null;
let _setValue = null;
let _setColumn = null;
let _setPlaceholderValue;

export async function openFeatureDialog(feature) {
  // Must happen first
  _setColumn(await VIEW_MODEL.getColumnInfo(feature.name));
  _setUniqueId(uuidv4());
  _setFeature(feature);

  const value = VIEW_MODEL.getValue(feature.name);
  if (value) {
    const isDefault = value.isDefault;
    const v = value.value;
    _setValue(isDefault ? "" : v);
    _setPlaceholderValue(isDefault ? v : "");
  }
}

export default function FeatureDialog() {
  const [uniqueId, setUniqueId] = React.useState(null);
  const [feature, setFeature] = React.useState(null);
  const [value, setValue] = React.useState(null);
  const [column, setColumn] = React.useState(null);
  const [placeholderValue, setPlaceholderValue] = React.useState(null);

  _setFeature = setFeature;
  _setUniqueId = setUniqueId;
  _setValue = setValue;
  _setColumn = setColumn;
  _setPlaceholderValue = setPlaceholderValue;

  const open = feature !== null;
  let featureType = null;
  if (open) {
    featureType = feature.type;
  }

  if (!open) return null;

  console.log(feature);
  console.log(column);

  const onCategoryClick = (category) => {
    category = "" + category;

    // Don't allow clicking in this scenario as the values typically won't match
    if (
      featureType === "categorical" &&
      column &&
      column.getType() === "datetime"
    ) {
      return;
    }

    if (featureType.startsWith("numeric")) {
      const vals = category.split(" - ");
      setValue(vals.length === 2 ? vals[0] : category);
      return category;
    } else if (featureType === "categorical" || featureType === "text") {
      setValue(category);
    }
  };

  return (
    <BaseDialogInner
      height={400}
      maxWidth={"sm"}
      key={uniqueId}
      title={<FormattedMessage id="setFeatureValue" />}
      open={open}
      callback={(ok) => {
        if (ok) {
          VIEW_MODEL.setValue(feature.name, value);
          (async () => {
            try {
              await VIEW_MODEL.predict();
            } catch (e) {
              LOGGER.error("Error predicting", e);
              APP.showErrorMessage(
                <FormattedMessage id="error.model.predict" />
              );
            }
          })();
        }
        setFeature(null);
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <>
            {featureType.startsWith("numeric") && (
              <NumericalType
                feature={feature}
                value={value}
                setValue={setValue}
                placeholderValue={placeholderValue}
              />
            )}
            {featureType === "categorical" && (
              <CategoricalType
                feature={feature}
                value={value}
                setValue={setValue}
                placeholderValue={placeholderValue}
              />
            )}
            {featureType === "text" && (
              <TextType
                feature={feature}
                value={value || placeholderValue}
                setValue={setValue}
                // placeholderValue={placeholderValue}
              />
            )}
            {featureType === "datetime" && (
              <DateType
                feature={feature}
                value={value || placeholderValue}
                setValue={setValue}
              />
            )}
          </>
        </Grid>
        {column && (
          <Grid item xs={12}>
            <DistributionChart
              overridesModel={VIEW_MODEL.getOverrides()}
              selectedColumn={column}
              hideTitle={true}
              onCategoryClick={onCategoryClick}
            />
            <Box sx={{ mt: 2 }}>
              <CommonStatusComponent
                selectedColumn={column}
                typeLabel={
                  <>
                    <FormattedMessage id="columnType" />:
                  </>
                }
                typeValue={column.getTypeName()}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </BaseDialogInner>
  );
}

function ValueAutocomplete({
  options,
  value,
  setValue,
  placeholderValue,
  renderInput,
  feature,
}) {
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 200,
  });

  return (
    <Autocomplete
      id="title autocomplete"
      options={options}
      getOptionLabel={(option) => {
        return option && option.value ? option.value : option;
      }}
      filterOptions={filterOptions}
      freeSolo
      inputValue={value}
      onInputChange={(e, y, z) => {
        if (z === "clear")
          // TODO: Reset to default value?
          setValue("");
      }}
      onChange={(e, v, r) => {
        let value = "";
        if (r === "selectOption") {
          value = v.value;
        }
        if (r === "createOption") {
          value = v;
        }
        if (r === "clear") {
          value = "";
        }
        setValue(value);
      }}
      renderInput={renderInput}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option.value, inputValue);
        const parts = parse(option.value, matches);
        return (
          <li {...props}>
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 900 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        );
      }}
    />
  );
}

function NumericalType({ feature, value, setValue, placeholderValue }) {
  const options = [];
  const cats = feature.categories;
  let lastValue = null;
  for (let i = 0; i < cats.length; i++) {
    const v = cats[i];
    if (v === lastValue) continue;
    lastValue = v;
    options.push({ value: "" + v });
  }

  return (
    <ValueAutocomplete
      options={options}
      value={value}
      placeholderValue={placeholderValue}
      setValue={setValue}
      feature={feature}
      renderInput={(params) => (
        <CommonTextField
          sx={{ maxWidth: "100%" }}
          {...params}
          label={VIEW_MODEL.getOverrides().getColumnAlias(feature.name)}
          InputLabelProps={{ shrink: true }}
          // helperText={helperText}
          placeholder={placeholderValue}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
      )}
    />
  );
}

function CategoricalType({ feature, value, setValue, placeholderValue }) {
  return (
    <SelectType
      title={VIEW_MODEL.getOverrides().getColumnAlias(feature.name)}
      value={value}
      setValue={setValue}
      placeholderValue={placeholderValue}
      feature={feature}
      opts={feature.categories}
    />
  );
}

function TextType({ feature, value, setValue, placeholderValue }) {
  return (
    <CommonTextField
      sx={{ maxWidth: "100%" }}
      label={VIEW_MODEL.getOverrides().getColumnAlias(feature.name)}
      InputLabelProps={{ shrink: true }}
      // placeholder={placeholderValue}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      // multiline={value ? true : false}
    />
  );
}

function DateType({ feature, value, setValue }) {
  return (
    <CommonDateTimeField
      sx={{ width: "100%" }}
      label={VIEW_MODEL.getOverrides().getColumnAlias(feature.name)}
      value={new Date(formatDateIn(value))}
      onChange={(newValue) => {
        setValue(formatDateOut(newValue));
      }}
    />
  );
}
