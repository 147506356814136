// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconBrandChrome, IconHelp, IconSitemap, /*IconChartBar,*/ IconSettings, IconUsers } from '@tabler/icons';

import AUTH_MODEL from '_machina/react/model/AuthModel';

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconSitemap
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const admin  = {
    id: 'sample-docs-roadmap',
    title: "Administration" /*<FormattedMessage id="others" />*/,
    icon: icons.IconHelp,
    type: 'group',
    isAvailable: () => {return AUTH_MODEL.hasPermission(AUTH_MODEL.PERM_ID_VIEW_ADMIN_SETTINGS)},
    children: [
        {
            id: 'sample-page6',
            title: "Settings",
            type: 'item',
            url: '#',
            icon: IconSettings,
            breadcrumbs: false
        },
        {
            id: 'users',
            title: <FormattedMessage id="users" />,
            type: 'item',
            url: '/users',
            icon: IconUsers,
            breadcrumbs: false
        },
    ]
};

export default admin;
