import BaseService from "./BaseService";
import CURRENT_USER from "_machina/react/model/CurrentUser";

/**
 * Service used to access sub-models
 */
class SubModelService extends BaseService {
  async findByModel(modelId) {
    const url = `/api/submodel/find-by-model/${modelId}`;
    return await this._fetchGet(await CURRENT_USER.getAuthToken(), url);
  }

  async getValues(subModelId) {
    const url = `/api/submodel/${subModelId}/values`;
    return await this._fetchGet(await CURRENT_USER.getAuthToken(), url);
  }

  async predict(subModelId, values) {
    const url = `/api/submodel/${subModelId}/predict`;
    return await this._fetchPost(await CURRENT_USER.getAuthToken(), url, {
      arg_values: values,
    });
  }

  async optimal(subModelId, value) {
    const url = `/api/submodel/${subModelId}/optimal`;
    return await this._fetchPost(await CURRENT_USER.getAuthToken(), url, {
      target_value: value,
    });
  }

  async scenarioStart(subModelId) {
    const url = `/api/submodel/${subModelId}/scenario-start`;
    return await this._fetchPost(await CURRENT_USER.getAuthToken(), url, {});
  }

  async scenarioSelectTarget(subModelId, target, features) {
    const url = `/api/submodel/${subModelId}/scenario-select-target`;
    const payload = {
      target_scenario: target,
    };
    if (features) {
      payload.wizard_values = features;
    }
    return await this._fetchPost(
      await CURRENT_USER.getAuthToken(),
      url,
      payload
    );
  }

  async scenarioRun(subModelId, target, scenarioState) {
    const url = `/api/submodel/${subModelId}/scenario-run`;
    return await this._fetchPost(await CURRENT_USER.getAuthToken(), url, {
      target_scenario: target,
      wizard_values: scenarioState,
    });
  }
}

// Singleton
const SUB_MODEL_SERVICE = new SubModelService();

export default SUB_MODEL_SERVICE;
