import React from "react";
import { FormattedMessage } from "react-intl";

import { useState } from "react";

import { MenuItem } from "@mui/material";

import CommonModelComponent from "../common/CommonModelComponent";
import CommonStatusComponent from "../common/CommonStatusComponent";
import ColumnsComponent from "../common/ColumnsComponent";
import DistributionChart from "../common/DistributionChart";
import TimeSeriesChart from "../common/TimeSeriesChart";
import FileAnalysisModel from "../FileAnalysisModel";
import DatasetOverridesModel from "../DatasetOverridesModel";

function StatusComponent({ state, setState, selectedColumn }) {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const model = selectedColumn.getColumns().getModel();

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const possibleTypes = selectedColumn.getPossibleModelTypes();
  possibleTypes.sort();
  const menuItems = [];

  const switchType = async (type) => {
    const col = model.getColumns().getColumn(selectedColumn.getName());
    col.setModelType(type);
    setState({ ...state });
  };

  const currentType = selectedColumn.getModelType();
  for (let i = 0; i < possibleTypes.length; i++) {
    const type = possibleTypes[i];
    if (type === currentType) continue;
    menuItems.push(
      <MenuItem
        key={type}
        onClick={() => {
          closeMenu();
          switchType(type);
        }}
      >
        {selectedColumn.getNameForModelType(type)}
      </MenuItem>
    );
  }

  return (
    <CommonStatusComponent
      menuAnchorEl={menuAnchorEl}
      setMenuAnchorEl={setMenuAnchorEl}
      selectedColumn={selectedColumn}
      menuItems={menuItems}
      closeMenu={closeMenu}
      typeLabel={
        <>
          <FormattedMessage id="modelType" />:
        </>
      }
      typeValue={selectedColumn.getModelTypeName()}
    />
  );
}

export default function ModelTargetsComponent({ validator, state, setState }) {
  const [selectedColumn, setSelectedColumn] = React.useState(null);

  if (!state?.dataset?.AnalysisResults) return;

  const fileAnalysis = new FileAnalysisModel(state.dataset.AnalysisResults);
  const columns = fileAnalysis.getColumns();
  const selCol = columns.getSelectedColumn(selectedColumn);
  const overridesModel = new DatasetOverridesModel(state);
  let tsDistribution = selCol.getTimeSeriesDistribution();
  let tsColumn = null;

  if (selCol.getModelType() === "timeseries") {
    tsDistribution = selCol.getColumns().getTimeSeriesDistribution();
    tsColumn = selCol.getName();
  }

  return (
    <CommonModelComponent
      validator={validator}
      fileModel={fileAnalysis}
      columnsComponent={
        <ColumnsComponent
          overridesModel={overridesModel}
          isFeatures={false}
          state={state}
          setState={setState}
          columns={columns}
          selectedColumn={selCol}
          setSelectedColumn={setSelectedColumn}
        />
      }
      chartComponent={
        tsDistribution ? (
          <TimeSeriesChart
            key={tsColumn ? undefined : selCol.getName()}
            distribution={tsDistribution}
            filterColumn={tsColumn}
            overridesModel={overridesModel}
          />
        ) : (
          <DistributionChart
            selectedColumn={selCol}
            overridesModel={overridesModel}
          />
        )
      }
      statusComponent={
        <StatusComponent
          state={state}
          setState={setState}
          selectedColumn={selCol}
        />
      }
    />
  );
}
