import React from "react";

import { useTheme } from "@mui/material";
import { Grid } from "@mui/material";
import { FormattedMessage } from "react-intl";

import Transitions from "ui-component/extended/Transitions";
import { CommonWizardStepPanel } from "_machina/react/components/common/wizard/CommonWizard";

import { PreviewTable } from "./preview/PreviewTable";
import DatasetOverridesModel from "../DatasetOverridesModel";

export const createDatasetPreviewStep = (validator) => {
  return {
    key: "datasetPreview",
    title: <FormattedMessage id="datasetPreview" />,
    onNext: (state, setState, forceUpdate) => {
      return true;
    },
    isEnabled: (state) => {
      return true;
    },
  };
};

export function DatasetPreviewStep({
  datasetPreviewStep,
  step,
  steps,
  state,
  setState,
}) {
  const theme = useTheme();

  if (!state || !state.transient.datasetPreview) {
    return <></>;
  }

  const overridesModel = new DatasetOverridesModel(state, setState);

  const preview = state.transient.datasetPreview;
  //console.log(preview);

  const columns = [];
  for (let i = 0; i < preview.columns.length; i++) {
    const col = preview.columns[i];
    columns.push({
      id: col,
      label: overridesModel.getColumnAlias(col),
    });
  }

  const rows = [];
  for (let i = 0; i < preview.data.length; i++) {
    const row = preview.data[i];
    const out = {};
    for (let j = 0; j < preview.columns.length; j++) {
      const c = preview.columns[j];
      out[c] = overridesModel.getColumnValueAlias(c, row[j]);
    }
    rows.push(out);
  }

  return (
    <CommonWizardStepPanel
      value={step}
      steps={steps}
      stepKey={datasetPreviewStep.key}
    >
      {steps[step].key === datasetPreviewStep.key && (
        <Transitions type="fade" in={true}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <PreviewTable
                overridesModel={overridesModel}
                state={state}
                setState={setState}
                columns={columns}
                rows={rows}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ mt: -2, ml: 1, color: theme.palette.warning.dark }}
            >
              {state?.transient?.datasetLimited > 0 && (
                <FormattedMessage
                  id="previewLimited"
                  values={{
                    rows: state.transient.datasetLimited,
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Transitions>
      )}
    </CommonWizardStepPanel>
  );
}
