import React from "react";

import { Typography, Grid } from "@mui/material";
import { FormattedMessage } from "react-intl";

import Transitions from "ui-component/extended/Transitions";
import { CommonWizardStepPanel } from "_machina/react/components/common/wizard/CommonWizard";

import ModelFeaturesComponent from "../features/ModelFeaturesComponent";
import FileAnalysisModel from "../FileAnalysisModel";

export const createModelFeaturesStep = (validator, title) => {
  return {
    key: "modelFeatures",
    title: title || <FormattedMessage id="modelFeatures" />,
    onNext: (state, setState, forceUpdate) => {
      validator.reset();
      const enabledCount = new FileAnalysisModel(
        state?.dataset?.AnalysisResults
      )
        .getColumns()
        .getEnabledColumnsCount();
      if (enabledCount < 2) {
        validator.checkMinLength(0, "modelFeaturesSelected", "");
      }
      if (validator.getMinInvalidTab() >= 0) {
        forceUpdate();
        return false;
      }
      return true;
    },
    onPrev: () => {
      validator.reset();
    },
    isEnabled: (state) => {
      return state.trainModel;
    },
  };
};

export function ModelFeaturesStep({
  modelFeaturesStep,
  step,
  steps,
  state,
  setState,
  validator,
}) {
  return (
    <CommonWizardStepPanel
      value={step}
      steps={steps}
      stepKey={modelFeaturesStep.key}
    >
      {steps[step].key === modelFeaturesStep.key && (
        <Transitions type="fade" in={true}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={9}>
              <Typography>
                <FormattedMessage id="wizard.dataset.modelFeatures.header" />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ModelFeaturesComponent
                state={state}
                setState={setState}
                validator={validator}
              />
            </Grid>
          </Grid>
        </Transitions>
      )}
    </CommonWizardStepPanel>
  );
}
