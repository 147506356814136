import React, { useEffect } from "react";
import { useTheme } from "@mui/system";

import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Grid } from "@mui/material";

import SideIconCard from "ui-component/cards/SideIconCard";
import Transitions from "ui-component/extended/Transitions";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";

import FeatureDialog from "./dialogs/FeatureDialog";
import FeaturesComponent from "./FeaturesComponent";
import StatusComponent from "./StatusComponent";
import TargetComponent from "./TargetComponent";
import TargetDialog from "./dialogs/TargetDialog";
import TitleComponent from "./TitleComponent";

import { LOGGER } from "_machina/util/Logging";
import APP from "_machina/react/model/App";
import VIEW_MODEL from "_machina/react/model/ViewModel";
import ModelTimeSeriesChart from "./charts/ModelTimeSeriesChart";
import FeatureImporancePieChart from "./charts/FeatureImportancePieChart";
import SankeyChart from "./charts/SankeyChart";
import SubModelPrimaryMetricBarChart from "./charts/SubModelPrimaryMetricBarChart";
import TitleSkeleton from "./skeletons/TitleSkeleton";
import MiniChartSkeleton from "./skeletons/MiniChartSkeleton";
import SankeySkeleton from "./skeletons/SankeySkeleton";
import FeaturesSkeleton from "./skeletons/FeaturesSkeleton";

import STRING_UTIL from "_machina/util/StringUtil";

// TODO: What to do if object does not exist?
//   Would be nice to use loader in react routes, but appears to not be working
//   Need a generate error page? or just blank and message?
// TODO: Left nav should change to models when viewing this page
// TODO: Need a general error page when page doesn't exist... just blank now
// TODO: Breakout time series and other predictions into separate pages

export default function ViewModelPage() {
  const [model, setModel] = React.useState(null);
  const [, setSubModels] = React.useState(null);
  const [, setSelectedSubModel] = React.useState(null);
  const [, setFeatures] = React.useState(null);
  const [, setTarget] = React.useState(null);
  const [, setTargetValue] = React.useState(null);
  const [, setPredictionResult] = React.useState(null);
  const [, setPredictionCount] = React.useState(null);
  const [, setValues] = React.useState(null);
  const [, setPredictMode] = React.useState(null);
  const [, setModelStatus] = React.useState(null);
  const [, setTimeSeriesDistribution] = React.useState(null);
  const { id } = useParams();
  const theme = useTheme();
  const i18n = APP.getI18n();

  VIEW_MODEL._setModel = setModel;
  VIEW_MODEL._setSubModels = setSubModels;
  VIEW_MODEL._setSelectedSubModel = setSelectedSubModel;
  VIEW_MODEL._setFeatures = setFeatures;
  VIEW_MODEL._setTarget = setTarget;
  VIEW_MODEL._setTargetValue = setTargetValue;
  VIEW_MODEL._setPredictionResult = setPredictionResult;
  VIEW_MODEL._setPredictionCount = setPredictionCount;
  VIEW_MODEL._setValues = setValues;
  VIEW_MODEL._setPredictMode = setPredictMode;
  VIEW_MODEL._setModelStatus = setModelStatus;
  VIEW_MODEL._setTimeSeriesDistribution = setTimeSeriesDistribution;

  const status = VIEW_MODEL.getStatus();
  console.log(status);

  const render = model !== null && VIEW_MODEL.isRenderable();

  useEffect(() => {
    return () => {
      VIEW_MODEL.stopTimer();
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await VIEW_MODEL.loadModel(id);
      } catch (e) {
        LOGGER.error("Error loading model", e);
        APP.showErrorMessage(<FormattedMessage id="error.model.loading" />);
      }
    })();
  }, [id]);

  const statusComponent = VIEW_MODEL.isStatusShown() && model !== null && (
    <Grid item xs={12} sm={12}>
      <StatusComponent model={model} status={status} />
    </Grid>
  );

  if (!render)
    return (
      <Grid container spacing={3}>
        {statusComponent}
        <Grid item xs={12} sm={8}>
          {model ? <TitleComponent model={model} /> : <TitleSkeleton />}
        </Grid>
        <Grid item xs={12} sm={4}>
          <TitleSkeleton />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <MiniChartSkeleton />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MiniChartSkeleton />
            </Grid>
            <Grid item xs={12}>
              <SankeySkeleton />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FeaturesSkeleton />
        </Grid>
      </Grid>
    );

  const isPredict = VIEW_MODEL.isPredictMode();
  const distribution = VIEW_MODEL.getPredictionDistribution();
  const isTimeSeries = distribution;
  let outDistribution = null;
  let tsHigh = null;
  let tsHighDate = null;
  let tsLow = null;
  let tsLowDate = null;
  let tsAverage = null;

  // "asPrediction": "{feature} (Prediction)",
  // "asDataset": "{feature} (Dataset)",

  if (isTimeSeries) {
    const target = VIEW_MODEL.getTargetName();
    const tsDistribution = VIEW_MODEL.getTimeSeriesDistribution();

    outDistribution = [];
    // TODO: Fix this..
    if (tsDistribution) {
      for (let i = 0; i < tsDistribution.length; i++) {
        const d = tsDistribution[i];
        const date = d.date;
        const values = d.values;
        for (let k in values) {
          if (k === target) {
            const outValues = {};
            const label = VIEW_MODEL.getOverrides().getColumnAlias(k);
            outValues[
              i18n.formatMessage({ id: "asDataset" }, { feature: label })
            ] = values[k];
            // outValues[k + " (Prediction)"] = values[k];
            outDistribution.push({
              date: date,
              values: outValues,
            });
          }
        }
      }
    }

    let sum = 0;
    let count = 0;
    for (let i = 0; i < distribution.length; i++) {
      const d = distribution[i];
      const date = d.date;
      const values = d.values;
      for (let k in values) {
        if (k === target) {
          count++;
          const val = values[k];
          sum += val;
          if (tsHigh === null || val > tsHigh) {
            tsHigh = val;
            tsHighDate = date;
          }
          if (tsLow === null || val < tsLow) {
            tsLowDate = date;
            tsLow = val;
          }

          const outValues = {};
          const label = VIEW_MODEL.getOverrides().getColumnAlias(k);
          outValues[
            i18n.formatMessage({ id: "asPrediction" }, { feature: label })
          ] = values[k];
          outDistribution.push({
            date: date,
            values: outValues,
          });
        }
      }
    }
    tsAverage = count > 0 ? sum / count : 0;
  }

  return (
    <>
      <Grid container spacing={3}>
        {statusComponent}
        <Grid item xs={12} sm={8}>
          <TitleComponent model={model} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TargetComponent isTimeSeries={isTimeSeries} />
        </Grid>
        <Grid item xs={12} sm={isTimeSeries ? 12 : 8}>
          <Grid container spacing={3}>
            {isTimeSeries ? (
              <>
                <Grid item xs={12}>
                  <ModelTimeSeriesChart
                    distribution={outDistribution}
                    overrides={VIEW_MODEL.getOverrides()}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <div key={tsHigh}>
                    <Transitions
                      type={"fade"}
                      timeout={{ appear: 1000, enter: 1000, exit: 1000 }}
                      in={true}
                    >
                      <SideIconCard
                        iconPrimary={KeyboardArrowUpRoundedIcon}
                        primary={Math.round(tsHigh * 100) / 100}
                        secondary={<FormattedMessage id="high" />}
                        secondarySub={
                          STRING_UTIL.isOnlyDates([tsHighDate])
                            ? STRING_UTIL.stripTime(tsHighDate)
                            : tsHighDate
                        }
                        color={theme.palette.success.dark}
                      />
                    </Transitions>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <div key={tsLow}>
                    <Transitions
                      type={"fade"}
                      timeout={{ appear: 1500, enter: 1500, exit: 1500 }}
                      in={true}
                    >
                      <SideIconCard
                        iconPrimary={KeyboardArrowDownRoundedIcon}
                        primary={Math.round(tsLow * 100) / 100}
                        secondary={<FormattedMessage id="low" />}
                        secondarySub={
                          STRING_UTIL.isOnlyDates([tsLowDate])
                            ? STRING_UTIL.stripTime(tsLowDate)
                            : tsLowDate
                        }
                        color={theme.palette.error.main}
                      />
                    </Transitions>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <div key={tsAverage}>
                    <Transitions
                      type={"fade"}
                      timeout={{ appear: 2000, enter: 2000, exit: 2000 }}
                      in={true}
                    >
                      <SideIconCard
                        iconPrimary={RemoveRoundedIcon}
                        primary={Math.round(tsAverage * 100) / 100}
                        secondary={<FormattedMessage id="average" />}
                        // secondarySub="30 days"
                        color={theme.palette.primary.dark}
                      />
                    </Transitions>
                  </div>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} sm={6}>
                  <SubModelPrimaryMetricBarChart
                    model={model}
                    subModelId={VIEW_MODEL.getSelectedSubModelId()}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FeatureImporancePieChart
                    subModelId={VIEW_MODEL.getSelectedSubModelId()}
                    isPredict={isPredict}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SankeyChart />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        {!isTimeSeries && (
          <Grid item xs={12} sm={4}>
            <FeaturesComponent />
          </Grid>
        )}
      </Grid>
      <FeatureDialog />
      <TargetDialog />
    </>
  );
}
