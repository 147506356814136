import * as React from "react";

import { FormattedMessage } from "react-intl";

import { IconButton, Tooltip } from "@mui/material";

import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";

export default function CommonTableEditColumn({ onClick, size, color }) {
  return (
    <Tooltip title={<FormattedMessage id="edit" />}>
      <IconButton
        color={color || "text-primary"}
        size={size || "large"}
        onClick={(e) => {
          e.stopPropagation();
          onClick(e);
        }}
      >
        <EditTwoToneIcon sx={{ fontSize: "1.3rem" }} />
      </IconButton>
    </Tooltip>
  );
}
