import BaseModel from "./BaseModel";
import MODEL_SERVICE from "_machina/service/ModelService";
import FileAnalysisModel from "../dialogs/dataset-wizard-dialog/FileAnalysisModel";

import { openModelEditDialog } from "../dialogs/model-edit-dialog";

class Model extends BaseModel {
  /**
   * Initializes the model
   * @param statusCb The optional status callback
   */
  init(statusCb) {
    super.init(statusCb);
  }

  async showModelEditDialog(id, callback) {
    this.showStatus();
    try {
      const result = await MODEL_SERVICE.get(id);
      await openModelEditDialog(result, { cb: callback });
    } finally {
      this.hideStatus();
    }
  }

  async updateModel(model) {
    this.showStatus();
    try {
      await MODEL_SERVICE.update(model);
    } finally {
      this.hideStatus();
    }
  }

  async getModelConfiguration(id) {
    this.showStatus();
    try {
      const modelConfig = await MODEL_SERVICE.getConfig(id);
      const analysis = new FileAnalysisModel(modelConfig);
      if (!(analysis.getTotalRows() > 0 || analysis.getTotalRows() === -1)) {
        throw new Error("Analysis model is invalid.");
      }
      return analysis;
    } finally {
      this.hideStatus();
    }
  }

  isPrivate(dataset) {
    return !dataset.Public && dataset.UserID;
  }

  _reset() {}
}

// Singleton
const MODEL = new Model();

export default MODEL;
