import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import { useTheme } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";

import STRING_UTIL from "_machina/util/StringUtil";
import MainCard from "ui-component/cards/MainCard";

function createBaseOptions() {
  return {
    chart: {
      id: "timeseries-chart",
      sparkline: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      animations: {
        animateGradually: false,
      },
      events: {
        mouseMove: function (event, chartContext, config) {
          var tooltip = chartContext.el.querySelector(".apexcharts-tooltip");
          var seriesIndex = config.seriesIndex;
          if (seriesIndex !== -1) {
            let data = config.config.series[seriesIndex].data;
            if (!data || data.length === 0) {
              tooltip.style.display = "none";
            } else {
              tooltip.style.display = "block";
            }
          }
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 4,
      strokeWidth: 2,
      hover: {
        size: 6,
      },
    },
    stroke: {
      curve: "straight",
      width: 3,
    },
    tooltip: {
      theme: "dark",
      shared: false,
      intersect: false,
      marker: {
        show: true,
      },
      x: {
        show: false,
      },
      y: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          if (
            typeof value !== "undefined" &&
            value != null &&
            isNaN(value) === false
          ) {
            return Math.round(value * 100) / 100;
          }
        },
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
      //categories: dates
    },
    //eslint-disable-next-line
    markers: {
      size: 0,
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      labels: {
        //colors: colors,
        useSeriesColors: false,
      },
    },
    grid: {
      show: true,
      // borderColor: darkLight + 20,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  };
}

const TimeSeriesChart = ({
  distribution,
  hideTitle,
  footer,
  onCategoryClick,
  overridesModel,
  onlyChart,
  height,
  filterColumn,
  chartKey,
}) => {
  const [options, setOptions] = useState(createBaseOptions());

  const theme = useTheme();
  const darkLight = theme.palette.dark.light;
  const { primary } = theme.palette.text;

  if (options) {
    options.grid.borderColor = darkLight + 50;
  }

  React.useEffect(() => {
    if (!distribution) {
      setOptions(null);
      return;
    }

    const dist = distribution;
    const dates = [];
    const series = {};
    const colors = [];

    for (let i = 0; i < dist.length; i++) {
      const curr = dist[i];
      const date = curr.date;
      const values = curr.values;
      dates.push(date);
      for (const key in values) {
        let seriesValues = series[key];
        if (!seriesValues) {
          seriesValues = [];
          series[key] = seriesValues;
        }
        seriesValues.push([i, values[key]]);
        // seriesValues.push(values[key])
      }
    }

    if (STRING_UTIL.isOnlyDates(dates)) {
      for (let cidx = 0; cidx < dates.length; cidx++) {
        dates[cidx] = STRING_UTIL.stripTime(dates[cidx]);
      }
    }

    const data = [];
    for (const key in series) {
      if (!filterColumn || filterColumn === key) {
        colors.push(primary);
        data.push({
          name: overridesModel.getColumnAlias(key),
          data: series[key],
        });
      }
    }

    const opts = createBaseOptions();
    opts.data = data;
    // opts.xaxis.categories = dates;
    opts.legend.labels.colors = colors;

    opts.xaxis.labels.formatter = (val) => {
      return dates[val];
    };

    setOptions(opts);
    //eslint-disable-next-line
  }, [distribution, filterColumn]);

  // if (!options || !options.data) return;

  console.log(options);

  return (
    <div>
      {onlyChart ? (
        <ReactApexChart
          options={options}
          series={options?.data ? options.data : []}
          type="area"
          height={height || 190}
        />
      ) : (
        <MainCard
          headerSX={{ m: 1.5, p: 0 }}
          contentSX={{ m: 0, my: -3, p: 0, px: 2 }}
          sx={{
            backgroundColor:
              theme.palette.mode === "dark"
                ? theme.palette.dark.light + 10
                : theme.palette.grey[200],
            borderColor:
              theme.palette.mode === "dark"
                ? theme.palette.dark.light + 15
                : theme.palette.grey[200],
          }}
          titleContent={
            hideTitle ? null : (
              <Typography variant="h4">
                <FormattedMessage id="timeSeriesDistribution" />
              </Typography>
            )
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ReactApexChart
                options={options}
                series={options?.data ? options.data : []}
                type="area"
                height={height || 190}
              />
            </Grid>
            {footer && (
              <Grid item xs={12}>
                {footer}
              </Grid>
            )}
          </Grid>
        </MainCard>
      )}
    </div>
  );
};

export default TimeSeriesChart;
