import defaultDataset from "./default-dataset.jpg";
import defaultModel from "./default-model.jpg";

export function getDefaultDatasetUrl() {
  return defaultDataset;
}

export function getDefaultModelUrl() {
  return defaultModel;
}
