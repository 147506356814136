import React from "react";

import { styled } from "@mui/material/styles";
import { Box, Button, Grid, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import MainCard from "ui-component/cards/MainCard";

import { LOGGER } from "_machina/util/Logging";
import { getDefaultModelUrl } from "_machina/assets/images";
import CommonLabeledImage from "_machina/react/components/common/CommonLabeledImage";
import APP from "_machina/react/model/App";
import VIEW_MODEL from "_machina/react/model/ViewModel";

const CardWrapper = styled(MainCard)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? theme.palette.dark.dark
      : theme.palette.primary.dark,
  color: "#fff",
  overflow: "hidden",
  position: "relative",
  "&>div": {
    position: "relative",
    zIndex: 5,
  },
  "&:after": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    background:
      theme.palette.mode === "dark"
        ? `linear-gradient(210.04deg, ${theme.palette.primary.dark} -50.94%, rgba(144, 202, 249, 0) 95.49%)`
        : theme.palette.primary[800],
    borderRadius: "50%",
    zIndex: 1,
    top: -85,
    right: -95,
    [theme.breakpoints.down("sm")]: {
      top: -105,
      right: -140,
    },
  },
  "&:before": {
    content: '""',
    position: "absolute",
    zIndex: 1,
    width: 210,
    height: 210,
    background:
      theme.palette.mode === "dark"
        ? `linear-gradient(140.9deg, ${theme.palette.primary.dark} -14.02%, rgba(144, 202, 249, 0) 82.50%)`
        : theme.palette.primary[800],
    borderRadius: "50%",
    top: -125,
    right: -15,
    opacity: 0.5,
    [theme.breakpoints.down("sm")]: {
      top: -155,
      right: -70,
    },
  },
}));

const TitleComponent = ({ model }) => {
  const handleModeChange = (isPredict) => {
    if (VIEW_MODEL.isPredictMode() === isPredict) return;

    (async () => {
      try {
        await VIEW_MODEL.switchModes(isPredict);
      } catch (e) {
        LOGGER.error("Error loading model", e);
        APP.showErrorMessage(<FormattedMessage id="error.model.loading" />);
      }
    })();
  };

  const isPredictMode = VIEW_MODEL.isPredictMode();
  const problemType = VIEW_MODEL.getCurrentSubModelProblemType();

  return (
    <CardWrapper border={false} content={false}>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item sx={{ overflow: "hidden" }}>
            {model && (
              <CommonLabeledImage
                title={model.Name}
                description={model.Description}
                imageUrl={model.ImageURL}
                defaultImageUrl={getDefaultModelUrl()}
              >
                {/* TODO: Ellipses is not working */}
                <Typography
                  sx={{
                    cursor: "default",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                  variant="h3"
                >
                  {model.Name}
                </Typography>
              </CommonLabeledImage>
            )}
          </Grid>
          <Grid item sx={{ display: "flex", alignItems: "center" }}>
            {VIEW_MODEL.isRenderable() && problemType !== "timeseries" && (
              <>
                <Button
                  disableElevation
                  variant={!isPredictMode ? "contained" : "text"}
                  size="small"
                  sx={{ color: "inherit" }}
                  onClick={(e) => handleModeChange(false)}
                >
                  <FormattedMessage id="optimal" />
                </Button>
                <Button
                  disableElevation
                  variant={isPredictMode ? "contained" : "text"}
                  size="small"
                  sx={{ color: "inherit" }}
                  onClick={(e) => handleModeChange(true)}
                >
                  <FormattedMessage id="predict" />
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </CardWrapper>
  );
};

export default TitleComponent;
