import React from "react";

import { Typography, Grid } from "@mui/material";
import { FormattedMessage } from "react-intl";

import Transitions from "ui-component/extended/Transitions";

import { selectFile } from "_machina/react/Util";
import { LOGGER } from "_machina/util/Logging";

import APP from "_machina/react/model/App";
import DATASET from "../../../model/Dataset";
import STRING_UTIL from "_machina/util/StringUtil";

import CommonButton from "_machina/react/components/common/fields/CommonButton";
import CommonSwitch from "_machina/react/components/common/fields/CommonSwitch";
import CommonTextField from "_machina/react/components/common/fields/CommonTextField";
import { CommonWizardStepPanel } from "_machina/react/components/common/wizard/CommonWizard";

import ViewModelSwitch from "../common/ViewModelSwitch";
import FileDetailsComponent from "../FileDetailsComponent";
import FileAnalysisModel from "../FileAnalysisModel";
import { initAnalysisResults } from "../util";

const MAX_DATASET_SIZE_MB = 1024;

export const createSelectFileStep = (validator) => {
  return {
    key: "selectFile",
    title: <FormattedMessage id="selectDataset" />,
    onNext: (state, setState, forceUpdate) => {
      validator.reset();
      validator.checkMinLength(0, "datasetFilename", state.datasetFilename);
      const results = state?.dataset?.AnalysisResults;
      if (results && !new FileAnalysisModel(results).isUsable()) {
        validator.checkMinLength(0, "datasetFilename", "");
      }
      if (validator.getMinInvalidTab() >= 0) {
        forceUpdate();
        return false;
      }
      return true;
    },
    isEnabled: (state) => {
      return true;
    },
  };
};

const selectDatasetFile = async (state, setState, validator) => {
  const file = await selectFile("*", false);
  // let succeeded = false;

  if (file.size > MAX_DATASET_SIZE_MB * 1024 * 1024) {
    APP.showWarningMessage(
      <FormattedMessage
        id="warning.image.maxSize"
        values={{ maxSize: MAX_DATASET_SIZE_MB }}
      />
    );
    return;
  }

  try {
    const result = await DATASET.uploadDatasetFile(file);
    if (!result.datasetUri) {
      throw Error("Unable to find datasetUri in response");
    }

    // Initialize the results
    initAnalysisResults(state.dataset, result);

    state.dataset.StoragePath = result.datasetUri;
    state.dataset.Name = STRING_UTIL.stripExtension(file.name);
    state.datasetFilename = file.name;
    state.transient = {};

    LOGGER.info(state);

    validator.reset();
    setState({ ...state });
    // succeeded = true;
  } catch (e) {
    LOGGER.error("Error seleting dataset file", e);
    APP.showErrorMessage(<FormattedMessage id="error.dataset.uploadFile" />);
  }
};

export function SelectFileStep({
  selectFileStep,
  step,
  steps,
  state,
  setState,
  validator,
  fileModel,
}) {
  return (
    <CommonWizardStepPanel
      value={step}
      steps={steps}
      stepKey={selectFileStep.key}
    >
      {steps[step].key === selectFileStep.key && (
        <Transitions type="fade" in={true}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={9}>
              <Typography>
                <FormattedMessage id="wizard.dataset.selectFile.header" />
              </Typography>
              <Typography sx={{ mt: 2 }}>
                <FormattedMessage id="wizard.dataset.selectFile.fileText" />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CommonTextField
                value={state.datasetFilename}
                sx={{ mr: 2, mb: 1 }}
                disabled
                required
                helperText={
                  !validator.isValid(0, "datasetFilename") ? (
                    <FormattedMessage id="validation.datasetFile" />
                  ) : null
                }
                error={!validator.isValid(0, "datasetFilename")}
              ></CommonTextField>
              <CommonButton
                onClick={() => {
                  selectDatasetFile(state, setState, validator);
                }}
              >
                <FormattedMessage id="select" />
                ...
              </CommonButton>
            </Grid>
            {fileModel.isAvailable() && (
              <>
                <Grid item xs={12} sx={{ mt: -2 }}>
                  <FileDetailsComponent fileModel={fileModel} />
                </Grid>
                {fileModel.isUsable() && (
                  <>
                    <Grid item xs={12}>
                      <CommonSwitch
                        label={<FormattedMessage id="trainModel" />}
                        checked={state.trainModel}
                        onChange={() => {
                          state.trainModel = !state.trainModel;
                          setState({ ...state });
                        }}
                      />
                    </Grid>
                    {state.trainModel && (
                      <Grid item xs={12} sx={{ mt: -2.5 }}>
                        <ViewModelSwitch state={state} setState={setState} />
                      </Grid>
                    )}
                  </>
                )}
              </>
            )}
          </Grid>
        </Transitions>
      )}
    </CommonWizardStepPanel>
  );
}
