import React from "react";

import { useState } from "react";
import { useTheme } from "@mui/system";
import { FormattedMessage } from "react-intl";

import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";

import {
  Box,
  ButtonBase,
  Switch,
  CardActions,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Stack,
  Typography,
  MenuItem,
} from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";

import MainCard from "ui-component/cards/MainCard";
import CommonMenu from "_machina/react/components/common/CommonMenu";
import CommonTooltip from "_machina/react/components/common/CommonTooltip";

const ColumnWarning = ({ column }) => {
  const theme = useTheme();
  const iconSx = { height: 14, width: 14 };
  const message = column.getShortStatusMessage();
  const color = theme.palette.warning.dark;
  const icon = <WarningRoundedIcon sx={{ ...iconSx, color: color }} />;

  return (
    <CommonTooltip title={column.getStatusMessage()} placement={"bottom-start"}>
      <Box
        sx={{
          display: "flex",
          alignItems: "top",
          p: 0,
          m: 0,
          mt: 0.7,
          opacity: 0.9,
        }}
      >
        <Box>{icon}</Box>
        <Box sx={{ marginLeft: "4px" }}>
          <Typography variant="subtitle2" sx={{ color: color }}>
            {message}
          </Typography>
        </Box>
      </Box>
    </CommonTooltip>
  );
};

const Column = ({
  isFeatures,
  state,
  setState,
  column,
  setSelectedColumn,
  selectedColumn,
  onExcludedChanged,
  overridesModel,
}) => {
  const theme = useTheme();

  const isSelected = selectedColumn.getName() === column.getName();
  const isUsable = isFeatures ? column.isUsable() : column.isUsableForPredict();

  return (
    <CommonTooltip
      title={overridesModel.getColumnDescription(column.getName())}
      placement="right"
      arrow
    >
      <ListItemButton
        sx={{ py: 0.5, px: 2 }}
        disableRipple={false}
        onClick={(e) => {
          e.stopPropagation();
          setSelectedColumn(column.getName());
        }}
        style={{
          backgroundColor: isSelected
            ? theme.palette.dark[900]
            : theme.palette.dark[800],
          opacity:
            isFeatures || isUsable
              ? isSelected
                ? 1
                : 0.9
              : isSelected
              ? 1
              : 0.4,
        }}
      >
        <ListItemText
          primary={
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box sx={{ overflow: "hidden" }}>
                <Box sx={{ mb: 0.5 }}>
                  <Typography
                    sx={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {overridesModel.getColumnAlias(column.getName())}
                  </Typography>
                </Box>
                <Box sx={{ overflow: "hidden" }}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontWeight: 800,
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {isFeatures ? (
                      <>
                        <FormattedMessage id="type" />: {column.getTypeName()}
                      </>
                    ) : (
                      <>
                        <FormattedMessage id="model" />:{" "}
                        {column.getModelTypeName()}
                      </>
                    )}
                  </Typography>
                </Box>
                {isFeatures && !isUsable && <ColumnWarning column={column} />}
              </Box>
              {(isFeatures || isUsable) && (
                <div
                  style={{ width: "45px", height: "25px" }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Switch
                    size="small"
                    checked={
                      isFeatures
                        ? column.isEnabled()
                        : column.isPredictEnabled()
                    }
                    onChange={(e) => {
                      if (isFeatures) {
                        column.setExcluded(!column.isExcluded());
                        if (onExcludedChanged) onExcludedChanged();
                      } else {
                        column.setPredictExcluded(!column.isPredictExcluded());
                      }
                      setState({ ...state });
                      e.stopPropagation();
                    }}
                  />
                </div>
              )}
            </Stack>
          }
        />
      </ListItemButton>
    </CommonTooltip>
  );
};

const ColumnsComponent = ({
  isFeatures,
  state,
  setState,
  columns,
  setSelectedColumn,
  selectedColumn,
  onExcludedChanged,
  overridesModel,
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const theme = useTheme();
  const title = isFeatures ? (
    <FormattedMessage id="features" />
  ) : (
    <FormattedMessage id="targets" />
  );

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const toggleColumns = (selectAll) => {
    let changed = false;
    const columns = selectedColumn.getColumns().getAllColumns();
    for (let i = 0; i < columns.length; i++) {
      const col = columns[i];
      if (isFeatures) {
        const excluded = col.isExcluded();
        col.setExcluded(!selectAll);
        changed = changed || excluded !== col.isExcluded();
      } else {
        col.setPredictExcluded(!selectAll);
      }
    }
    setState({ ...state });

    if (changed && onExcludedChanged) onExcludedChanged();
  };

  return (
    <>
      <MainCard
        key={isFeatures ? "featuresComponent" : "targetsComponent"}
        headerSX={{ m: 1.5, p: 0 }}
        sx={{
          backgroundColor:
            theme.palette.mode === "dark"
              ? theme.palette.dark.light + 10
              : theme.palette.grey[200],
          borderColor:
            theme.palette.mode === "dark"
              ? theme.palette.dark.light + 15
              : theme.palette.grey[200],
        }}
        titleContent={<Typography variant="h4">{title}</Typography>}
        secondary={
          <ButtonBase
            disableRipple
            onClick={(event) => {
              setMenuAnchorEl(event.currentTarget);
            }}
          >
            <MoreHorizRoundedIcon />
          </ButtonBase>
        }
        content={false}
      >
        <PerfectScrollbar style={{ height: 270 }}>
          <List
            component="nav"
            sx={{ paddingTop: 0, paddingBottom: 0, overflowX: "hidden" }}
          >
            {columns.getFeaturesByStatus(!isFeatures).map((column, index) => {
              return (
                <div key={column.getName()}>
                  {index > 0 && <Divider />}
                  <Column
                    isFeatures={isFeatures}
                    state={state}
                    setState={setState}
                    column={column}
                    selectedColumn={selectedColumn}
                    setSelectedColumn={setSelectedColumn}
                    onExcludedChanged={onExcludedChanged}
                    overridesModel={overridesModel}
                  />
                </div>
              );
            })}
          </List>
        </PerfectScrollbar>
        <Divider />
        <CardActions sx={{ p: 1.25, justifyContent: "center" }}></CardActions>
      </MainCard>
      {menuAnchorEl && (
        <CommonMenu
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          anchorEl={menuAnchorEl}
          onClose={closeMenu}
        >
          <MenuItem
            onClick={() => {
              toggleColumns(true);
              closeMenu();
            }}
          >
            <ListItemIcon>
              <ToggleOnIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage id="selectAll" />
            </ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              toggleColumns(false);
              closeMenu();
            }}
          >
            <ListItemIcon>
              <ToggleOffIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage id="unselectAll" />
            </ListItemText>
          </MenuItem>
        </CommonMenu>
      )}
    </>
  );
};

export default ColumnsComponent;
