import React from "react";
import { useIntl } from "react-intl";
import { Alert, Button, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Transitions from "ui-component/extended/Transitions";
import VIEW_MODEL from "_machina/react/model/ViewModel";
import * as BaseViewModel from "_machina/react/model/BaseViewModel";

const StatusComponent = ({ model, status }) => {
  const theme = useTheme();
  const intl = useIntl();

  let icon = null;
  let circularIcon = false;
  let message = "Unknown message.";
  let severity = "warning";
  let action = null;
  let sx = null;

  if (!status) return null;

  console.log(status);

  if (status.status === BaseViewModel.StatusPreTraining) {
    sx = {
      borderColor:
        theme.palette.warning.dark /*, color: theme.palette.warning.dark*/,
    };
    message = intl.formatMessage({ id: "training.status.preTraining" });
    severity = "warning";
    circularIcon = true;
  } else if (status.status === BaseViewModel.StatusTraining) {
    sx = {
      borderColor:
        theme.palette.primary.main /*, color: theme.palette.info.dark*/,
    };
    let percent = ((status.steps / status.targetSteps) * 100).toFixed(2) + "%";
    if (status.steps === status.targetSteps) percent = "100%";
    message = intl.formatMessage(
      {
        id: "training.status.training",
      },
      { percent: percent }
    );
    severity = "info";
    circularIcon = true;
  } else if (status.status === BaseViewModel.StatusErrored) {
    sx = {
      borderColor:
        theme.palette.error.main /*, color: theme.palette.error.dark*/,
    };
    message = intl.formatMessage({ id: "training.status.errored" });
    if (status.errorMessage) {
      const emLower = status.errorMessage.toLowerCase();
      if (emLower.indexOf("invalid file") !== -1) {
        message = intl.formatMessage({
          id: "training.status.errored.invalidFile",
        });
      } else if (emLower.indexOf("no valid submodel") !== -1) {
        message = intl.formatMessage({
          id: "training.status.errored.noValidSubmodel",
        });
      }
    }

    severity = "error";
  } else if (status.status === BaseViewModel.StatusCompleted) {
    sx = {
      borderColor: theme.palette.success.dark,
      color: theme.palette.success.dark,
    };
    message = intl.formatMessage({ id: "training.status.completed" });
    severity = "success";
  }

  if (status.startTime !== status.trainingUpdate && status.steps > 0) {
    action = (
      <Transitions in={true} type="slide" direction="left">
        <Button
          sx={{ color: sx.borderColor }}
          size="small"
          onClick={() => {
            VIEW_MODEL.loadModel(model.ID);
          }}
        >
          {intl.formatMessage({ id: "refresh" })}
        </Button>
      </Transitions>
    );
  }

  if (circularIcon) icon = <CircularProgress size={15} color={severity} />;

  return (
    <>
      {message ? (
        <Transitions in={message ? true : false} type="slide" direction="down">
          <Alert
            variant="outlined"
            severity={severity}
            sx={{ ...sx, overflow: "hidden" }}
            icon={icon}
            action={action}
          >
            {message}
          </Alert>
        </Transitions>
      ) : null}
    </>
  );
};

export default StatusComponent;
