import { Card, CardContent, Grid } from "@mui/material";
import TextSkeleton from "./TextSkeleton";
import RectangularSkeleton from "./RectangularSkeleton";

const ImageCardSkeleton = () => (
  <Card sx={{ backgroundColor: "#29314f", maxWidth: "300px", margin: "auto" }}>
    <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
      <RectangularSkeleton
        width={"100%"}
        height={150}
        sx={{ backgroundColor: "#303550", borderRadius: "4px 4px 0 0" }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
            sx={{ padding: "16px" }}
          >
            <Grid item xs zeroMinWidth>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <TextSkeleton />
                </Grid>
                <Grid item xs={10}>
                  <TextSkeleton />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default ImageCardSkeleton;
