import React from "react";

// material-ui
import { Grid } from "@mui/material";

import { CommonDialogTabPanel } from "_machina/react/components/common/dialog/CommonDialog";
import CommonImageChooser from "../fields/CommonImageChooser";

export default function CommonImageTabPanel({
  tab,
  value,
  imageUrl,
  setImageUrl,
  defaultImageUrl,
  getChooserState,
  setChooserState,
}) {
  return (
    <CommonDialogTabPanel tab={tab} value={value}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CommonImageChooser
            defaultImageUrl={defaultImageUrl}
            currentImage={imageUrl}
            onSetImage={(image) => {
              setImageUrl(image);
            }}
            getChooserState={getChooserState}
            setChooserState={setChooserState}
          />
        </Grid>
      </Grid>
    </CommonDialogTabPanel>
  );
}
