import isEmail from "validator/lib/isEmail";
export default class Validator {
  errors = [];
  callbacks = {};

  updateErrors(tab, prop, valid) {
    let found = false;
    for (let i = 0; i < this.errors.length; i++) {
      const e = this.errors[i];
      if (e.prop === prop && e.tab === tab) {
        found = true;
        if (valid) {
          this.errors.splice(i, 1);
        }
        break;
      }
    }
    if (!valid && !found) {
      this.errors.push({
        prop: prop,
        tab: tab,
      });
    }

    // console.log("### UPDATE VALIDATION");
    // for (let i = 0; i < this.errors.length; i++) {
    //   const e = this.errors[i];
    //   console.log(e);
    // }
  }

  checkMinLength(tab, prop, str, minLen = 1) {
    const valid = str !== undefined && str.trim().length >= minLen;
    this.updateErrors(tab, prop, valid);
    return valid;
  }

  /**
   * Returns whether the specified email address is valid
   * If not, it is added to the list of current errors.
   * @param {int} tab the tab index
   * @param {string} prop The field name
   * @param {string} email The email address
   * @returns Whether the specified email address is valid
   */
  checkValidEmail(tab, prop, email) {
    const valid = isEmail(email);
    this.updateErrors(tab, prop, valid);
    return valid;
  }

  reset() {
    // console.log('## RESET');
    this.errors = [];
    this.callbacks = {};
  }

  getMinInvalidTab() {
    let min = -1;
    for (let i = 0; i < this.errors.length; i++) {
      const e = this.errors[i];
      if (min === -1 || e.tab < min) {
        min = e.tab;
      }
    }
    return min;
  }

  isTabValid(tab) {
    for (let i = 0; i < this.errors.length; i++) {
      const e = this.errors[i];
      if (e.tab === tab) {
        return false;
      }
    }
    return true;
  }

  isValid(tab, prop) {
    for (let i = 0; i < this.errors.length; i++) {
      const e = this.errors[i];
      if (e.tab === tab && e.prop === prop) {
        return false;
      }
    }
    return true;
  }

  addCallback(key, cb) {
    this.callbacks[key] = cb;
  }

  executeCallbacks() {
    for (const id in this.callbacks) {
      this.callbacks[id](this);
    }
  }
}
