import React from "react";

import { Grid, Slider, Typography } from "@mui/material";

export default function CommonSliderField({
  label,
  sx,
  onChange,
  value,
  ...other
}) {
  const [intermediateValue, setIntermediateValue] = React.useState(null);
  const handleChange = (event, newValue) => {
    setIntermediateValue(newValue);
  };

  return (
    <Grid
      container
      direction="column"
      sx={{ maxWidth: 450, minWidth: 200, ...sx }}
    >
      <Grid
        item
        xs={12}
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <Typography>{label}</Typography>
        </Grid>
        <Grid item xs>
          <Slider
            value={intermediateValue || value}
            onChange={handleChange}
            onChangeCommitted={(event, newValue) => {
              setIntermediateValue(newValue);
              onChange(newValue);
            }}
            {...other}
          />
        </Grid>
        <Grid item>
          <Typography variant="h5">{intermediateValue || value}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
