import { useTheme } from "@mui/system";
import { Box, Typography, Grid } from "@mui/material";

export default function CommonInfoBox({ children, sx, ...other }) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        p: 1.5,
        pb: 1,
        border: "1px solid",
        borderRadius: 2,
        backgroundColor:
          theme.palette.mode === "dark"
            ? theme.palette.dark.light + 10
            : theme.palette.grey[200],
        borderColor:
          theme.palette.mode === "dark"
            ? theme.palette.dark.light + 15
            : theme.palette.grey[200],
        ":hover": {
          boxShadow:
            theme.palette.mode === "dark"
              ? "0 2px 14px 0 rgb(33 150 243 / 10%)"
              : "0 2px 14px 0 rgb(32 40 45 / 8%)",
        },
        ...sx,
      }}
      {...other}
    >
      <Grid container spacing={0.7} direction="column">
        {children}
      </Grid>
    </Box>
  );
}

export function CommonInfoBoxRow({ name, value, valueSx, valueOnClick }) {
  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={2.5} sx={{ minWidth: 90 }}>
          <Typography variant="h5">{name}</Typography>
        </Grid>
        <Grid item sx={valueSx} onClick={valueOnClick}>
          {value}
        </Grid>
      </Grid>
    </Grid>
  );
}
