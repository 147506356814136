import DEFAULT_CONFIG from "./config-default.json";
import * as test from "../test/TestUtil";
/**
 * Configuration settings for the application
 */
class Config {
  /**
   * @constructor
   */
  constructor() {
    // The default configuration values
    this.config = DEFAULT_CONFIG;
    // Loads the configuration that overrides the default values
    const customConfig = this._getCustomConfiguration();
    if (customConfig) {
      this.config = { ...this.config, ...customConfig };
    }
  }

  /**
   * Forces the "local" configuration to be loaded. The local configuration
   * typically contains deployment-specific configuration settings, etc.
   * (URLs, keys, etc.)
   */
  forceLocalConfig() {
    this.config = { ...this.config, ...this._getCustomConfiguration(true) };
  }

  /**
   * Returns the active "custom" configuration. This configuration could be
   * the "local" configuration, or it may be a configuration that is used when
   * running test cases, etc.
   * @param {boolean} forceLocal Whether to force the "local" configuration
   *    to be loaded.
   * @returns The configuration
   */
  _getCustomConfiguration(forceLocal = false) {
    try {
      // Look for a local configuration (if applicable)
      if (!forceLocal && test.isUnitTestRunning()) {
        return require("./config-test.json");
      } else {
        return require("./config-local.json");
      }
    } catch (e) {
      /* Not an error condition */
    }
    return {};
  }

  /**
   * Returns the current logging level (used by the logger)
   * @returns The current logging level
   */
  getLoggingLevel() {
    return this.config.loggingLevel;
  }

  /**
   * Returns the current Firebase configuration settings
   * @returns The current Firebase configuration settings
   */
  getFirebaseConfig() {
    return this.config.firebaseConfig;
  }

  /**
   * Returns the base URL for the application
   * @returns The base URL for the application
   */
  getBaseUrl() {
    return this.config.baseUrl;
  }

  setBaseUrl(url) {
    this.config.baseUrl = url;
  }

  /**
   * Returns the ordered list of surveys
   * @returns The ordered list of surveys
   */
  getSurveys() {
    return this.config.surveys;
  }

  /**
   * Returns the value for the specified configuration setting
   * @param {string} name The name of the configuration settings
   * @param {boolean} required Whether the property is required
   * @returns {string} The value corresponding to the specified name
   */
  get(name, required = true) {
    const value = this.config[name];
    if (required && value === undefined) {
      throw new Error(
        `The following configuration property was not found: ${name}`
      );
    }
    return this.config[name];
  }
}

// Singleton
const CONFIG = new Config();
// Provides access to the underlying class for unit tests, etc.
CONFIG._class = Config;

export default CONFIG;
