import React from "react";
import { FormattedMessage } from "react-intl";

import { Grid } from "@mui/material";
import { uuidv4 } from "_machina/util/Uuid";
import {
  CommonDialog,
  CommonDialogTabPanel,
} from "_machina/react/components/common/dialog/CommonDialog";
import CommonTextField from "_machina/react/components/common/fields/CommonTextField";
import { ValuesTable } from "./ValuesTable";
import DatasetOverridesModel from "../../DatasetOverridesModel";
import { useForceUpdate } from "_machina/react/Util";
import { LOGGER } from "_machina/util/Logging";

const columnTab = "column";
const valuesTab = "values";

let _setUniqueId = null;
let _setColumn = null;
let _setTab = null;
let _overridesModel = null;
let _callback = null;
let _values = null;

const VALUES_LIMIT = 50;

export async function openEditDialog(column, state, setState, callback) {
  // Get the values
  let values = null;
  try {
    values = await new DatasetOverridesModel(state).getColumnValues(
      column,
      VALUES_LIMIT + 1
    );
    values = values.uniqueValues;
    if (values.length > VALUES_LIMIT) {
      values = null;
    }
  } catch (e) {
    // TODO: what to do with this
    LOGGER.warn(e);
  }

  _values = values;
  _overridesModel = new DatasetOverridesModel(
    JSON.parse(JSON.stringify(state))
  ); // clone the state
  _callback = callback;

  _setTab(columnTab);
  _setColumn(column);
  _setUniqueId(uuidv4());
}

export function EditDialog() {
  const [uniqueId, setUniqueId] = React.useState(null);
  const [column, setColumn] = React.useState(null);
  const [tab, setTab] = React.useState(columnTab);
  const forceUpdate = useForceUpdate();

  _setColumn = setColumn;
  _setUniqueId = setUniqueId;
  _setTab = setTab;

  const open = column !== null;

  if (!open) return null;

  const overridesModel = _overridesModel;
  const values = _values;

  const tabs = [
    {
      value: columnTab,
      label: <FormattedMessage id="details" />,
    },
  ];

  if (values) {
    tabs.push({
      value: valuesTab,
      label: <FormattedMessage id="values" />,
    });
  }

  const description = overridesModel.getColumnDescription(column);

  return (
    <>
      <CommonDialog
        key={uniqueId}
        title={
          <FormattedMessage
            id="columnWithName"
            values={{
              columnName: column,
            }}
          />
        }
        open={open}
        callback={(ok) => {
          if (ok) {
            _callback(overridesModel);
          }
          setColumn(null);
        }}
        tab={tab}
        setTab={setTab}
        fullWidth={true}
        maxWidth={"sm"}
        height={400}
        tabs={tabs}
        tabPanels={
          open && (
            <>
              <CommonDialogTabPanel tab={tab} value={columnTab}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <CommonTextField
                      label={<FormattedMessage id="columnAlias" />}
                      value={overridesModel.getColumnAlias(column, false)}
                      onChange={(e) => {
                        overridesModel.setColumnAlias(column, e.target.value);
                        forceUpdate();
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CommonTextField
                      multiline
                      rows={5}
                      label={<FormattedMessage id="description" />}
                      value={description ? description : ""}
                      onChange={(e) => {
                        overridesModel.setColumnDescription(
                          column,
                          e.target.value
                        );
                        forceUpdate();
                      }}
                    />
                  </Grid>
                </Grid>
              </CommonDialogTabPanel>
              {values && (
                <CommonDialogTabPanel tab={tab} value={valuesTab}>
                  <ValuesTable
                    column={column}
                    values={values}
                    overridesModel={overridesModel}
                    forceUpdate={forceUpdate}
                  />
                </CommonDialogTabPanel>
              )}
            </>
          )
        }
      />
      <EditDialog />
    </>
  );
}
