import BaseService from "./BaseService";
import CURRENT_USER from "_machina/react/model/CurrentUser";

/**
 * Service used to access models
 */
class ModelService extends BaseService {
  async getList(
    page = 0,
    pageSize = 25,
    sortColumn = null,
    sortAscending = true,
    filter = null
  ) {
    let url = `/api/model/list?page=${page}&pageSize=${pageSize}`;
    if (sortColumn) {
      url = `${url}&sortColumn=${sortColumn}&sortAscending=${sortAscending}`;
    }
    if (filter) {
      url = `${url}&filter=${filter}`;
    }
    // console.log(url)
    return await this._fetchGet(await CURRENT_USER.getAuthToken(), url);
  }

  async get(modelId) {
    const url = `/api/model/${modelId}`;
    return await this._fetchGet(await CURRENT_USER.getAuthToken(), url);
  }

  async getConfig(modelId) {
    const url = `/api/model/config/${modelId}`;
    return await this._fetchGet(await CURRENT_USER.getAuthToken(), url);
  }

  async delete(modelId) {
    const url = `/api/model/${modelId}`;
    return await this._fetchDelete(await CURRENT_USER.getAuthToken(), url);
  }

  async update(model) {
    const url = `/api/model/${model.ID}`;
    return await this._fetchPut(await CURRENT_USER.getAuthToken(), url, model);
  }
}

// Singleton
const MODEL_SERVICE = new ModelService();

export default MODEL_SERVICE;
