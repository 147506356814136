import { useState } from "react";

import {
  ButtonBase,
  CardMedia,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";

// import AlertItemDelete from './AlertItemDelete';

// assets
import MoreVertTwoToneIcon from "@mui/icons-material/MoreVertTwoTone";

const Item = ({ item, title, index, content }) => {
  const backProfile = null;

  const { userStory } = {
    userStory: [
      {
        acceptance: "",
        assign: "",
        columnId: "column-1",
        description: "blah blah",
        id: "0",
        priority: "low",
        title: "itemmmm",
        itemIds: ["0"],
      },
    ],
  };

  const itemStory = userStory.filter(
    (story) => story?.itemIds?.filter((itemId) => itemId === item.id)[0]
  )[0];

  const handlerDetails = (id) => {};

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // eslint-disable-next-line
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line
  const handleModalClose = (status) => {
    setOpen(false);
    if (status) {
    }
  };

  // eslint-disable-next-line
  const [openStoryDrawer, setOpenStoryDrawer] = useState(false);
  // eslint-disable-next-line
  const handleStoryDrawerOpen = () => {
    setOpenStoryDrawer((prevState) => !prevState);
  };

  // eslint-disable-next-line
  const editStory = () => {
    setOpenStoryDrawer((prevState) => !prevState);
  };

  return (
    <>
      <div
        style={{
          userSelect: "none",
          marginBottom: 8,
          padding: 16,
          backgroundColor: "rgb(33, 41, 70)",
          borderRadius: 8,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: itemStory ? -0.75 : 0 }}
        >
          <Typography
            onClick={() => handlerDetails(item.id)}
            variant="subtitle1"
            sx={{
              display: "inline-block",
              width: "calc(100% - 34px)",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              verticalAlign: "middle",
              cursor: "pointer",
            }}
          >
            {title}
          </Typography>

          <ButtonBase
            sx={{ borderRadius: "12px" }}
            onClick={handleClick}
            aria-controls="menu-comment"
            aria-haspopup="true"
          >
            <IconButton component="span" size="small" disableRipple>
              <MoreVertTwoToneIcon fontSize="inherit" />
            </IconButton>
          </ButtonBase>
          <Menu
            id="menu-comment"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            variant="selectedMenu"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                handlerDetails(item.id);
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                setOpen(true);
              }}
            >
              Delete
            </MenuItem>
          </Menu>
          {/* {open && <AlertItemDelete title={item.title} open={open} handleClose={handleModalClose} />} */}
        </Stack>
        {/* {itemStory && ( */}
        <>
          {/* <Stack direction="row" spacing={0.5} alignItems="center">
                            <Tooltip title="User Story">
                                <MenuBookTwoToneIcon color="secondary" sx={{ fontSize: '0.875rem' }} />
                            </Tooltip>
                            <Tooltip title={"Foo"}>
                                <Link
                                    variant="caption"
                                    color="secondary"
                                    underline="hover"
                                    sx={{ cursor: 'pointer', pt: 0.5 }}
                                >
                                    User Story #1
                                </Link>
                            </Tooltip>
                        </Stack> */}
        </>
        {/* )} */}
        {backProfile && (
          <CardMedia
            component="img"
            image={backProfile}
            sx={{ width: "100%", borderRadius: 1, mt: 1.5 }}
            title="Slider5 image"
          />
        )}
        {content}
      </div>
    </>
  );
};

export default Item;
