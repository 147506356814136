import { LOGGER } from "_machina/util/Logging";
import BaseViewModel from "./BaseViewModel";
import SUB_MODEL_SERVICE from "_machina/service/SubModelService";

const truncateByDecimalPlace = (value, numDecimalPlaces) =>
  Math.trunc(value * Math.pow(10, numDecimalPlaces)) /
  Math.pow(10, numDecimalPlaces);

function formatDecimal(number) {
  return truncateByDecimalPlace(number, 3);
}

function formatNumeric(value) {
  const v0 = formatDecimal(value[0]);
  const v1 = formatDecimal(value[1]);

  return `${v0} - ${v1}`;
}

// {
//   "ID": 26,
//   "ModelID": 3,
//   "ColumnName": "CommuteTime",
//   "StorageFolder": "gs://cts-test/model/ab146c0d-8376-408e-9cef-dffcd1124756/sub-model/0c582871-bcec-4e3d-9787-d6ac4922c0bc/",
//   "ProblemType": "classification",
//   "PrimaryMetric": 0.2749435,
//   "Step": 2,
//   "ErrorMessage": null
// }
class SubModel {
  constructor(scenariosModel, submodel) {
    this._scenariosModel = scenariosModel;
    this._submodel = submodel;
  }

  getId() {
    return this._submodel.ID;
  }

  getName() {
    return this._submodel.ColumnName;
  }

  getFormattedName() {
    return this._scenariosModel.getOverrides().getColumnAlias(this.getName());
  }
}

class TargetFeatureOption {
  constructor(scenariosModel, feature) {
    this._scenariosModel = scenariosModel;
    this._feature = feature;
  }

  getName() {
    return this._feature.name;
  }

  getType() {
    return this._feature.type;
  }

  getFormattedName() {
    return this._scenariosModel.getOverrides().getColumnAlias(this.getName());
  }
}

class Feature {
  constructor(scenariosModel, feature) {
    this._scenariosModel = scenariosModel;
    this._feature = feature;
  }

  getName() {
    return this._feature.name;
  }

  getFormattedName() {
    return this._scenariosModel.getOverrides().getColumnAlias(this.getName());
  }

  formatValue(value) {
    const type = this.getType();
    if (type === "numeric") {
      return formatNumeric(value);
    } else if (type === "categorical") {
      return this._scenariosModel
        .getOverrides()
        .getColumnValueAlias(this.getName(), value);
    }
  }

  getFormattedValues() {
    const out = [];
    const defValues = this._feature.defaultValues;
    for (let i = 0; i < defValues.length; i++) {
      let value = defValues[i][0];
      out.push(this.formatValue(value));
      //  const type = this.getType();
      //  if (type === 'numeric') {
      //     out.push(formatNumeric(value))
      //  } else if (type === 'categorical') {
      //     out.push(this._scenariosModel.getOverrides().getColumnValueAlias(
      //         this.getName(),
      //         value
      //     ));
      //  }
    }
    return out;
  }

  getType() {
    return this._feature.type;
  }
}

class ScenariosModel extends BaseViewModel {
  constructor() {
    super();
    this._forceUpdate = null;
  }

  getResults() {
    return this._results;
  }

  getSelectedSubModel() {
    return this._subModelsById[this._subModelId];
  }

  getSelectedSubModelColumnName() {
    return this.getSelectedSubModel().ColumnName;
  }

  getSelectedSubModelProblemType() {
    return this.getSelectedSubModel().ProblemType;
  }

  getTargetFeatureOptions() {
    const current = {};
    for (let i = 0; i < this._features.length; i++) {
      const f = this._features[i];
      current[f.getName()] = true;
    }

    const opts = [];

    for (let i = 0; i < this._targetFeatureOptions.length; i++) {
      const t = this._targetFeatureOptions[i];
      if (current[t.getName()]) continue;
      opts.push(t);
    }

    opts.sort((a, b) => {
      return a.getFormattedName().localeCompare(b.getFormattedName());
    });
    return opts;
  }

  getSelectedSubModelTarget() {
    return new SubModel(this, this.getSelectedSubModel());
  }

  getSubModelTargets() {
    const results = [];
    for (let i = 0; i < this._subModels.length; i++) {
      results.push(new SubModel(this, this._subModels[i]));
    }

    results.sort((a, b) => {
      return a.getFormattedName().localeCompare(b.getFormattedName());
    });
    return results;
  }

  getTargetDefaultValues() {
    return this._targetDefaultValues;
  }

  getFormattedTargetDefaultValues() {
    return this._getFormattedTargetValues(this.getTargetDefaultValues());
  }

  getTargetValues() {
    return this._targetValues;
  }

  getFormattedTargetValues() {
    return this._getFormattedTargetValues(this.getTargetValues());
  }

  getFormattedTargetValue(subModelId, value) {
    const subModel = this._subModelsById[subModelId];
    const problemType = subModel.ProblemType;
    if (problemType === "regression") {
      return `${value[0]} - ${value[1]}`;
    } else if (problemType === "classification") {
      return this.getOverrides().getColumnValueAlias(
        subModel.ColumnName,
        value
      );
    }
  }

  _getFormattedTargetValues(values) {
    const out = [];
    for (let i = 0; i < values.length; i++) {
      const value = values[i];
      out.push(this.getFormattedTargetValue(this._subModelId, value));
    }
    console.log(out);
    return out;
  }

  getFeatures() {
    return this._features;
  }

  // Select a sub-model (target)

  // Scenario start --->
  //     Returns, values for target
  //     Distribution
  //     FeatureOptions

  // ScenarioSelectTarget -->
  //     Features for the target (top 3)

  // Scenarios run
  //     Results

  // 1st come in:
  //    - Select sub model
  //    - scenario start
  //    - scenario select target

  // Target selection
  //    - select sub model
  //    - scenario start
  //    - scenario select target
  //    If nothing changes, should not do that
  //    Should not do anything if you didn't change target or values... no-op

  async retrieveTargetDefaultValues(subModelId) {
    this.showStatus(null);
    try {
      let result = await SUB_MODEL_SERVICE.scenarioStart(subModelId);
      return result.defaultValues;
    } finally {
      this.hideStatus();
    }
  }

  async updateTarget(subModelId, targetValues) {
    this.showStatus(null);
    try {
      this.resetSubModel();

      LOGGER.info(`## updateTarget, subModel=${subModelId}`);
      this._subModelId = subModelId;

      console.log(this._subModelsById);
      console.log(this._subModelsById[this._subModelId]);

      let result = await SUB_MODEL_SERVICE.scenarioStart(subModelId);
      console.log(result);
      this._targetDefaultValues = result.defaultValues;

      for (let i = 0; i < result.features.length; i++) {
        const f = result.features[i];
        this._targetFeatureOptions.push(new TargetFeatureOption(this, f));
      }
      console.log(this._targetFeatureOptions);

      this._targetValues = targetValues;
      if (!targetValues) {
        const target = result.defaultValues[0];
        this._targetValues = [target];
        targetValues = this._targetValues;
      }

      // TODO: Support multiple target values when backend is updated
      result = (
        await SUB_MODEL_SERVICE.scenarioSelectTarget(
          subModelId,
          targetValues[0]
        )
      ).results;
      this._features = [];
      for (let i = 0; i < result.length; i++) {
        const f = new Feature(this, result[i]);
        this._features.push(f);
      }

      await this._scenarioRun();

      // console.log("## result:");
      // console.log(this._features);
      // // TODO: Support multiple target values when backend is updated
      // result = await SUB_MODEL_SERVICE.scenarioRun(
      //   subModelId,
      //   targetValues[0],
      //   result
      // );
      // this._results = result.results;
      // console.log(this._results);
      // // Force the UI to update
      // this._forceUpdate();
    } finally {
      this.hideStatus();
    }
  }

  _getFeaturesAsJson() {
    const result = [];
    for (let i = 0; i < this._features.length; i++) {
      result.push(this._features[i]._feature);
    }
    return result;
  }

  async _scenarioRun() {
    this.showStatus(null);
    try {
      // TODO: Support multiple target values when backend is updated
      const result = await SUB_MODEL_SERVICE.scenarioRun(
        this._subModelId,
        this._targetValues[0],
        this._getFeaturesAsJson()
      );
      this._results = result.results;
      console.log(this._results);

      // Force the UI to update
      this._forceUpdate();
    } finally {
      this.hideStatus();
    }
  }

  async addFeature(featureName) {
    this.showStatus(null);

    const originalFeatures = this._features;
    const newFeatures = [];
    let success = false;
    try {
      const features = this._getFeaturesAsJson();
      features.push({ name: featureName });

      // TODO: Support multiple target values when backend is updated
      const result = (
        await SUB_MODEL_SERVICE.scenarioSelectTarget(
          this._subModelId,
          this._targetValues[0],
          features
        )
      ).results;

      for (let i = 0; i < result.length; i++) {
        const f = new Feature(this, result[i]);
        newFeatures.push(f);
      }

      this._features = newFeatures;
      await this._scenarioRun();
      success = true;
    } finally {
      if (!success) this._features = originalFeatures;
      this._forceUpdate();
      this.hideStatus();
    }
  }

  async removeFeature(featureName) {
    this.showStatus(null);

    const originalFeatures = this._features;
    const newFeatures = [];
    let success = false;
    try {
      for (let i = 0; i < this._features.length; i++) {
        const f = this._features[i];
        if (f.getName() === featureName) continue;
        newFeatures.push(f);
      }

      this._features = newFeatures;
      await this._scenarioRun();
      success = true;
    } finally {
      if (!success) this._features = originalFeatures;
      this._forceUpdate();
      this.hideStatus();
    }
  }

  async onSelectSubModel(id) {
    await this.updateTarget(id, null);
  }

  resetSubModel() {
    this._subModelId = null;
    this._defaultValues = [];
    this._targetDefaultValues = [];
    this._targetFeatureOptions = [];
    this._features = [];
    this._results = [];
  }

  _reset() {
    super._reset();
    this.resetSubModel();
  }
}

// Singleton
const SCENARIOS_MODEL = new ScenariosModel();

export default SCENARIOS_MODEL;
