import { useTheme } from "@mui/material/styles";
import {
  Tooltip,
  IconButton,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { getDefaultModelUrl } from "_machina/assets/images";
import MainCard from "ui-component/cards/MainCard";
import CommonLabeledImage from "_machina/react/components/common/CommonLabeledImage";
import TroubleshootRoundedIcon from "@mui/icons-material/TroubleshootRounded";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";

const ScenarioCard = ({
  primary,
  secondary,
  content,
  iconPrimary,
  model,
  color,
}) => {
  const theme = useTheme();
  const matchDownXs = useMediaQuery(theme.breakpoints.down("sm"));

  if (!model) return <></>;

  return (
    <MainCard
      sx={{ backgroundColor: "#363C58", opacity: 0.8, position: "relative" }}
    >
      <TroubleshootRoundedIcon
        fontSize="large"
        sx={{
          width: 160,
          height: 160,
          opacity: "0.12",
          position: "absolute",
          right: 2,
          top: -30,
          color: "#fff",
        }}
      />
      <Grid container direction={matchDownXs ? "column" : "row"} spacing={1}>
        <Grid item xs={12}>
          <CommonLabeledImage
            imageSx={{
              width: 32,
              height: 32,
              marginRight: -0.5,
              marginTop: -0.3,
            }}
            title={model.Name}
            description={model.Description}
            imageUrl={model.ImageURL}
            defaultImageUrl={getDefaultModelUrl()}
          >
            <Typography
              sx={{
                cursor: "default",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
              variant="h3"
            >
              {model.Name} Scenario
            </Typography>
          </CommonLabeledImage>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={0} sx={{ marginBottom: -1 }}>
            <Grid item>
              <Typography variant="h5" color="text" sx={{ opacity: 0.85 }}>
                {/* {secondary} */}
                Provides a high-level description of the scenario.
              </Typography>
            </Grid>
            <Grid item sx={{ paddingLeft: 1, marginTop: -1 }}>
              <Tooltip title={"Edit"}>
                <IconButton color={color || "text-primary"}>
                  <EditTwoToneIcon sx={{ fontSize: "1.3rem" }} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default ScenarioCard;
