import * as React from "react";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import CommonTooltip from "../CommonTooltip";

export default function CommonSwitch({
  label,
  checked,
  sx,
  onChange,
  tooltip,
  ...other
}) {
  const switchControl = (
    <FormControlLabel
      sx={{ whiteSpace: "nowrap" }}
      control={<Switch checked={checked} onChange={onChange} />}
      label={label}
    />
  );

  return (
    <Box sx={{ ...sx }} {...other}>
      {tooltip !== undefined && tooltip.length > 0 ? (
        <CommonTooltip title={tooltip}>{switchControl}</CommonTooltip>
      ) : (
        <>{switchControl}</>
      )}
    </Box>
  );
}
