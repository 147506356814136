import * as React from "react";

import { alpha } from "@mui/material/styles";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

// assets
import Toolbar from "@mui/material/Toolbar";
import { FormattedMessage } from "react-intl";
import TableCheckboxSkeleton from "../skeleton/TableCheckboxSkeleton";

function TableToolBar({ selected, renderToolbarItems }) {
  const theme = useTheme();
  return (
    <Toolbar
      sx={{
        padding: 1,
        overflow: "hidden",
        borderRadius: 2,
        bgcolor: alpha(theme.palette.divider, 0.04),
      }}
    >
      {renderToolbarItems(selected)}

      {selected.length ? (
        <Typography
          sx={{ flex: "1 1 100%", whiteSpace: "noWrap" }}
          color="inherit"
          variant="subtitle1"
          component="div"
          align="right"
        >
          {selected.length} <FormattedMessage id="selected" />
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
          align="right"
        ></Typography>
      )}
    </Toolbar>
  );
}

function EnhancedTableHead({
  isSkeleton,
  disableSelection,
  headCells,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {!disableSelection && (
          <TableCell padding="checkbox" sx={{ pl: 3 }}>
            {!isSkeleton ? (
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            ) : (
              <TableCheckboxSkeleton />
            )}
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.disableSort ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const CommonTableView = ({
  isSkeleton,
  disableSelection,
  handleRequestSort,
  headCells,
  isSelected,
  orderAscending,
  orderBy,
  renderRow,
  renderToolbarItems,
  rows,
  selected,
  setSelected,
}) => {
  const theme = useTheme();

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      if (selected.length > 0) {
        setSelected([]);
      } else {
        const newSelectedId = rows.map((n) => n.ID);
        setSelected(newSelectedId);
      }
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  return (
    <>
      {renderToolbarItems && (
        <TableToolBar
          selected={selected}
          renderToolbarItems={renderToolbarItems}
        />
      )}
      <TableContainer>
        <Table
          sx={
            {
              /*minWidth: 500*/
            }
          }
        >
          <EnhancedTableHead
            isSkeleton={isSkeleton}
            disableSelection={disableSelection}
            headCells={headCells}
            theme={theme}
            numSelected={selected.length}
            order={orderAscending ? "asc" : "desc"}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            selected={selected}
          />
          <TableBody>
            {rows.map((row, index) => {
              const isItemSelected = isSelected(row.ID);

              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                  onClick={(event) => !isSkeleton && handleClick(event, row.ID)}
                  selected={isItemSelected}
                >
                  {!disableSelection && (
                    <TableCell padding="checkbox" sx={{ pl: 3 }}>
                      {!isSkeleton ? (
                        <Checkbox color="primary" checked={isItemSelected} />
                      ) : (
                        <TableCheckboxSkeleton />
                      )}
                    </TableCell>
                  )}
                  {renderRow(row, index)}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CommonTableView;
