import React from "react";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FormattedMessage } from "react-intl";

import AUTH_MODEL from "_machina/react/model/AuthModel";

export default function UserRoleField({ state, setState }) {
  const options = [];
  const roles = AUTH_MODEL.getRoles();
  for (let role in roles) {
    options.push({
      value: role,
      name: <FormattedMessage id={role} />,
    });
  }

  return (
    <FormControl
      variant="outlined"
      sx={{ m: 0, minWidth: 200, maxWidth: "93%" }}
    >
      <>
        <InputLabel>{<FormattedMessage id="role" />}</InputLabel>
        <Select
          MenuProps={{
            disableScrollLock: true,
          }}
          autoWidth
          value={state.user.Role}
          onChange={(e) => {
            state.user.Role = e.target.value;
            setState({ ...state });
          }}
          label={<FormattedMessage id="role" />}
        >
          {options
            ? options.map((menuItem) => {
                return (
                  <MenuItem
                    sx={{ minWidth: "200px" }}
                    key={menuItem.value}
                    value={menuItem.value}
                  >
                    {menuItem.name}
                  </MenuItem>
                );
              })
            : null}
        </Select>
      </>
    </FormControl>
  );
}
