import React from "react";
import { Grid, Typography, Box, MenuItem } from "@mui/material";
import CommonButton from "_machina/react/components/common/fields/CommonButton";
import CommonMenu from "_machina/react/components/common/CommonMenu";

import MainCard from "ui-component/cards/MainCard";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import SettingsBackupRestoreRoundedIcon from "@mui/icons-material/SettingsBackupRestoreRounded";
import Board from "./board";
import Target from "./target";

import ScenariosDistributionChart from "./distribution";
import SCENARIOS_MODEL from "_machina/react/model/ScenariosModel";

import TagRoundedIcon from "@mui/icons-material/Tag";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";

import { LOGGER } from "_machina/util/Logging";
import APP from "_machina/react/model/App";

export default function Configuration({ openTargetDialog }) {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const [features, setFeatures] = React.useState(null);

  const handleMenuClick = (event) => {
    event.stopPropagation();
    const scenarioFeatures = SCENARIOS_MODEL.getTargetFeatureOptions();
    console.log(scenarioFeatures);

    if (scenarioFeatures.length > 0) {
      setMenuAnchorEl(event.currentTarget);
      setFeatures(scenarioFeatures);
    }
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ margin: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MainCard
                sx={{ backgroundColor: "#2e3556" }}
                // title="Target Outcome and Distribution"
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      mt: -1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h4">Target Outcome</Typography>
                    <CommonButton
                      color="primary"
                      variant="text"
                      startIcon={<EditTwoToneIcon />}
                      onClick={() => openTargetDialog()}
                    >
                      Edit
                    </CommonButton>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{ mt: -1, display: "flex", alignItems: "center" }}
                  >
                    <Typography variant="h4" sx={{ ml: 0.5 }}>
                      Outcome Distribution (Dataset)
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Target divSx={{ height: "150px" }} />
                  </Grid>
                  <Grid item xs={6}>
                    <ScenariosDistributionChart
                      divSx={{ height: "150px" }}
                      distribution={[
                        ["Yes", 237],
                        ["No", 1233],
                      ]}
                    />
                  </Grid>
                </Grid>
              </MainCard>
            </Grid>

            <Grid item xs={12}>
              <MainCard
                title={"Features"}
                sx={{ backgroundColor: "#2e3556" }}
                contentSX={{ padding: 1.5 }}
                secondary={
                  <Grid container spacing={1.5} alignItems="center">
                    <Grid item>
                      <CommonButton
                        color="primary"
                        variant="text"
                        startIcon={<AddRoundedIcon />}
                        onClick={handleMenuClick}
                      >
                        Add
                      </CommonButton>
                    </Grid>
                    <Grid item>
                      <CommonButton
                        color="primary"
                        variant="text"
                        startIcon={<SettingsBackupRestoreRoundedIcon />}
                      >
                        Reset
                      </CommonButton>
                    </Grid>
                  </Grid>
                }
              >
                <Box sx={{ overflowX: "scroll" }}>
                  <Board />
                </Box>
              </MainCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {menuAnchorEl && (
        <CommonMenu
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          anchorEl={menuAnchorEl}
          onClose={closeMenu}
        >
          {features.map((feature) => {
            return (
              <MenuItem
                key={feature.name}
                onClick={async () => {
                  closeMenu();
                  try {
                    await SCENARIOS_MODEL.addFeature(feature.getName());
                  } catch (e) {
                    LOGGER.error("Error attempting to add feature.", e);
                    APP.showErrorMessage("Error attempting to add feature.");
                  }
                }}
              >
                {feature.getType() === "numeric" ? (
                  <TagRoundedIcon
                    fontSize="small"
                    sx={{ mr: 1 }}
                    color="secondary"
                  />
                ) : (
                  <FormatListBulletedRoundedIcon
                    fontSize="small"
                    sx={{ mr: 1 }}
                    color="secondary"
                  />
                )}
                {SCENARIOS_MODEL.getOverrides().getColumnAlias(
                  feature.getFormattedName()
                )}
              </MenuItem>
            );
          })}
        </CommonMenu>
      )}
    </>
  );
}
