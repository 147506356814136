import React from "react";

import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { useForceUpdate } from "_machina/react/Util";
import { uuidv4 } from "_machina/util/Uuid";
import { LOGGER } from "_machina/util/Logging";

import APP from "_machina/react/model/App";
import DATASET_LIST from "_machina/react/model/DatasetList";
import WORKFLOW from "_machina/react/model/Workflow";

import { CommonWizard } from "_machina/react/components/common/wizard/CommonWizard";
import Validator from "_machina/react/components/common/validation/Validator";

import FileAnalysisModel from "./FileAnalysisModel";

import { createSelectFileStep, SelectFileStep } from "./steps/SelectFileStep";
import {
  createModelTargetsStep,
  ModelTargetsStep,
} from "./steps/ModelTargetsStep";
import {
  createModelFeaturesStep,
  ModelFeaturesStep,
} from "./steps/ModelFeaturesStep";
import {
  createDatasetDetailsStep,
  DatasetDetailsStep,
} from "./steps/DatasetDetailsStep";
import {
  createDatasetPreviewStep,
  DatasetPreviewStep,
} from "./steps/DatasetPreviewStep";

let _setUniqueId = null;
let _setOpen = null;

let _chooserState = null;

const setChooserState = (state) => {
  _chooserState = state;
};

const getChooserState = () => {
  return _chooserState;
};

const validator = new Validator();

export function openDatasetWizard() {
  _chooserState = null;
  _setUniqueId(uuidv4());
  _setOpen(true);
}

export default function DatasetWizardDialog() {
  const [uniqueId, setUniqueId] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  _setUniqueId = setUniqueId;
  _setOpen = setOpen;

  if (!open) return;

  return (
    <DatasetWizardDialogInner key={uniqueId} open={open} setOpen={setOpen} />
  );
}

const selectFileStep = createSelectFileStep(validator);
const datasetDetailsStep = createDatasetDetailsStep(validator);
const datasetPreviewStep = createDatasetPreviewStep(validator);
const modelTargetsStep = createModelTargetsStep(validator);
const modelFeaturesStep = createModelFeaturesStep(validator);

const STEPS = [
  selectFileStep,
  datasetDetailsStep,
  datasetPreviewStep,
  modelFeaturesStep,
  modelTargetsStep,
];

function DatasetWizardDialogInner({ open, setOpen }) {
  const [step, setStep] = React.useState(0);
  const [state, setState] = React.useState({
    dataset: {
      Name: "",
      Description: "",
      StoragePath: "",
    },
    datasetFilename: "",
    trainModel: true,
    viewModel: true,
    iterations: 2,
    transient: {},
  });
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();

  console.log(state);

  const onFinish = () => {
    (async () => {
      let succeeded = false;
      let result = null;
      try {
        const postState = JSON.parse(JSON.stringify(state));
        const model = new FileAnalysisModel(postState.dataset.AnalysisResults);
        delete postState.dataset.AnalysisResults;
        delete postState.transient;
        postState.AnalysisResults = model.cloneForPost().getAnalysis();

        LOGGER.info("## POSTING:");
        LOGGER.info(postState);

        result = await WORKFLOW.importDataset(postState);
        succeeded = true;
        // succeeded = false;
      } catch (e) {
        LOGGER.error("Error creating dataset", e);
        APP.showErrorMessage(
          <FormattedMessage id="error.datasets.importing" />
        );
      }
      if (succeeded) {
        // Force dataset list to refresh
        if (state.viewModel && result?.modelId >= 0) {
          navigate(`/view-model/${result.modelId}`);
        } else {
          DATASET_LIST.getDatasets();
        }
        setOpen(false);
      }
    })();

    return false;
  };

  const onCancel = () => {
    return true;
  };

  const fileModel = new FileAnalysisModel(state?.dataset?.AnalysisResults);

  return (
    <CommonWizard
      title={<FormattedMessage id="importDataset" />}
      onCancel={onCancel}
      onFinish={onFinish}
      open={open}
      setOpen={setOpen}
      step={step}
      setStep={setStep}
      steps={STEPS}
      stepsInfo={forceUpdate}
      state={state}
      setState={setState}
      height={540}
      stepPanels={
        open && (
          <>
            <SelectFileStep
              selectFileStep={selectFileStep}
              step={step}
              steps={STEPS}
              state={state}
              setState={setState}
              validator={validator}
              fileModel={fileModel}
            />
            <DatasetDetailsStep
              datasetDetailsStep={datasetDetailsStep}
              step={step}
              steps={STEPS}
              state={state}
              setState={setState}
              validator={validator}
              setChooserState={setChooserState}
              getChooserState={getChooserState}
            />
            <DatasetPreviewStep
              datasetPreviewStep={datasetPreviewStep}
              step={step}
              steps={STEPS}
              state={state}
              setState={setState}
              validator={validator}
            />
            <ModelFeaturesStep
              modelFeaturesStep={modelFeaturesStep}
              step={step}
              steps={STEPS}
              state={state}
              setState={setState}
              validator={validator}
            />
            <ModelTargetsStep
              modelTargetsStep={modelTargetsStep}
              step={step}
              steps={STEPS}
              state={state}
              setState={setState}
              validator={validator}
            />
          </>
        )
      }
    />
  );
}
