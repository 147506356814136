import React from "react";

// material-ui
import { FormattedMessage } from "react-intl";
import { LOGGER } from "_machina/util/Logging";

import { uuidv4 } from "_machina/util/Uuid";
import { getDefaultDatasetUrl } from "_machina/assets/images";
import { CommonDialog } from "_machina/react/components/common/dialog/CommonDialog";
import APP from "_machina/react/model/App";
import DATASET from "_machina/react/model/Dataset";
import CURRENT_USER from "_machina/react/model/CurrentUser";
import CommonObjectDetailsTabPanel from "_machina/react/components/common/dialog/CommonObjectDetailsTabPanel";
import CommonImageTabPanel from "_machina/react/components/common/dialog/CommonImageTabPanel";
import Validator from "_machina/react/components/common/validation/Validator";
import { useForceUpdate } from "_machina/react/Util";

let _setUniqueId = null;
let _setCallback = null;
let _setDataset = null;
let _setOwnableUser = null;
let _chooserState = null;

const setChooserState = (state) => {
  _chooserState = state;
};

const getChooserState = () => {
  return _chooserState;
};

const validator = new Validator();

const GENERAL_TAB = 0;
const IMAGE_TAB = 1;

export async function openDatasetEditDialog(dataset, callback) {
  _chooserState = null;
  _setUniqueId(uuidv4());
  _setCallback(callback);
  _setDataset(dataset);
  _setOwnableUser(await CURRENT_USER.getCurrentUser());
  validator.reset();
}

export default function DatasetEditDialog() {
  const [callback, setCallback] = React.useState(null);
  const [uniqueId, setUniqueId] = React.useState(null);
  const [dataset, setDataset] = React.useState(null);
  const [ownableUser, setOwnableUser] = React.useState(null);

  _setUniqueId = setUniqueId;
  _setDataset = setDataset;
  _setCallback = setCallback;
  _setOwnableUser = setOwnableUser;

  return (
    <DatasetEditDialogInner
      key={uniqueId}
      callback={async (ok) => {
        if (!ok) {
          if (callback?.cb) callback.cb(false);
          setDataset(null); // close dialog
        } else {
          let succeeded = false;
          try {
            await DATASET.updateDataset(dataset);
            succeeded = true;
          } catch (e) {
            LOGGER.error("Error saving dataset", e);
            APP.showErrorMessage(
              <FormattedMessage id="error.dataset.saving" />
            );
          }
          if (succeeded) {
            if (callback?.cb) callback.cb(true);
            setDataset(null); // close dialog
          }
        }
      }}
      dataset={dataset}
      setDataset={setDataset}
      ownableUser={ownableUser}
    />
  );
}

function DatasetEditDialogInner({
  callback,
  dataset,
  setDataset,
  ownableUser,
}) {
  const [tab, setTab] = React.useState(GENERAL_TAB);
  const forceUpdate = useForceUpdate();

  const open = dataset !== null;

  if (!open) return;

  const addValidatorCallback = (key, cb) => {
    validator.addCallback(key, cb);
  };

  const validateCallback = (ok) => {
    if (ok) {
      validator.executeCallbacks();
      const minTab = validator.getMinInvalidTab();
      if (minTab >= 0) {
        setTab(minTab);
        forceUpdate();
        return false;
      }
    }
    callback(ok);
  };

  return (
    <CommonDialog
      title={<FormattedMessage id="editDataset" />}
      open={open}
      callback={validateCallback}
      tab={tab}
      setTab={setTab}
      tabs={[
        {
          value: GENERAL_TAB,
          label: <FormattedMessage id="general" />,
        },
        {
          value: IMAGE_TAB,
          label: <FormattedMessage id="image" />,
        },
      ]}
      tabPanels={
        open && (
          <>
            <CommonObjectDetailsTabPanel
              tab={tab}
              value={GENERAL_TAB}
              object={dataset}
              setObject={(object) => {
                setDataset({ ...object });
              }}
              addValidatorCallback={addValidatorCallback}
              validator={validator}
              ownableUser={ownableUser}
            />
            <CommonImageTabPanel
              tab={tab}
              value={IMAGE_TAB}
              imageUrl={dataset.ImageURL}
              defaultImageUrl={getDefaultDatasetUrl()}
              setImageUrl={(imageUrl) => {
                setDataset({ ...dataset, ImageURL: imageUrl });
              }}
              getChooserState={getChooserState}
              setChooserState={setChooserState}
            />
          </>
        )
      }
    />
  );
}
