import * as React from "react";

import { FormattedMessage } from "react-intl";

import { IconButton, Tooltip } from "@mui/material";

import AutoGraphRoundedIcon from "@mui/icons-material/AutoGraphRounded";

export default function CommonTableCreateModelColumn({ onClick }) {
  return (
    <Tooltip title={<FormattedMessage id="createModel" />}>
      <IconButton
        color="text-primary"
        size="large"
        onClick={(e) => {
          e.stopPropagation();
          onClick(e);
        }}
      >
        <AutoGraphRoundedIcon sx={{ fontSize: "1.3rem" }} />
      </IconButton>
    </Tooltip>
  );
}
