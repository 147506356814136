import React from "react";
import { FormattedMessage } from "react-intl";

import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";

import CommonInfoBox from "_machina/react/components/common/CommonInfoBox";
import { CommonInfoBoxRow } from "_machina/react/components/common/CommonInfoBox";
import CommonMenu from "_machina/react/components/common/CommonMenu";

export default function CommonStatusComponent({
  menuAnchorEl,
  setMenuAnchorEl,
  selectedColumn,
  menuItems,
  closeMenu,
  typeLabel,
  typeValue,
}) {
  const theme = useTheme();

  const model = selectedColumn.getColumns().getModel();
  const totalRows = model.getTotalRows();
  const uniquePercent =
    totalRows >= 0
      ? ((selectedColumn.getUniqueValues() / totalRows) * 100).toFixed(2)
      : null;
  const emptyPercent =
    totalRows >= 0
      ? ((selectedColumn.getEmptyValues() / totalRows) * 100).toFixed(2)
      : null;
  const hasMenu = menuItems && menuItems.length > 0;

  return (
    <>
      <CommonInfoBox sx={{ m: 0 }}>
        <CommonInfoBoxRow
          name={
            <>
              <FormattedMessage id="values" />:
            </>
          }
          value={
            selectedColumn.getEmptyValues() ? (
              <FormattedMessage
                id="uniqueAndEmptyValues"
                values={{
                  unique: selectedColumn.getUniqueValues(),
                  uniquePercent:
                    uniquePercent !== null ? ` (${uniquePercent}%)` : "",
                  empty: selectedColumn.getEmptyValues(),
                  emptyPercent:
                    emptyPercent !== null ? `(${emptyPercent}%)` : "",
                }}
              />
            ) : (
              <FormattedMessage
                id="uniqueValues"
                values={{
                  unique: selectedColumn.getUniqueValues(),
                  uniquePercent:
                    uniquePercent !== null ? `(${uniquePercent}%)` : "",
                }}
              />
            )
          }
        />
        <CommonInfoBoxRow
          name={typeLabel}
          value={
            <Box sx={{ display: "flex", alignItems: "top", p: 0, m: 0 }}>
              <Box sx={{ marginRight: "8px" }}>{typeValue}</Box>
              {hasMenu && (
                <Box>
                  <EditTwoToneIcon
                    sx={{
                      fontSize: "1.2rem",
                      color: theme.palette.primary.dark,
                    }}
                  />
                </Box>
              )}
            </Box>
          }
          valueSx={hasMenu ? { cursor: "pointer" } : {}}
          valueOnClick={
            hasMenu
              ? (event) => {
                  setMenuAnchorEl(event.currentTarget);
                }
              : undefined
          }
        />
      </CommonInfoBox>
      {menuAnchorEl && (
        <CommonMenu
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          anchorEl={menuAnchorEl}
          onClose={closeMenu}
        >
          {menuItems}
        </CommonMenu>
      )}
    </>
  );
}
