// material-ui
import { Box } from "@mui/material";

import Column from "./column";
import Item from "./item";
// import ScenariosDistributionChart from "./distribution";
import SCENARIOS_MODEL from "_machina/react/model/ScenariosModel";

const item = {
  id: "1",
  title: "test-item2",
  dueDate: "02032002",
  image: false,
  assign: "",
  description: "",
  priority: "low",
  attachments: [],
};

const Board = () => {
  let outFeatures = [];
  const features = SCENARIOS_MODEL.getFeatures();
  for (let i = 0; i < features.length; i++) {
    const f = features[i];

    const formattedValues = f.getFormattedValues();
    const values = [];

    for (let j = 0; j < formattedValues.length; j++) {
      const v = formattedValues[j];
      values.push(<Item key={v} title={v} item={item} />);
    }

    outFeatures.push(
      <Column
        key={f.getName()}
        name={f.getName()}
        title={f.getFormattedName()}
        type={f.getType()}
      >
        {values}
      </Column>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      {outFeatures}
      {/* <Column title={"Age"} numerical={true}>
        <Item
          title={"18 - 29"}
          item={item}
          content={
            <Box sx={{ marginTop: 2, borderRadius: 1.5 }}>
              <ScenariosDistributionChart
                sparkline={true}
                divSx={{ height: "60px" }}
                distribution={[
                  ["Yes", 237],
                  ["No", 1233],
                ]}
              />
            </Box>
          }
        />
        <Item title={"29 - 34"} item={item} />
        <Item title={"34 - 38"} item={item} />
        <Item title={"38 - 45"} item={item} />
        <Item title={"45 - 60"} item={item} />
      </Column>
      <Column title={"Overtime"}>
        <Item title={"Yes"} item={item} />
        <Item title={"No"} item={item} />
      </Column>
      <Column title={"Travel"}>
        <Item title={"Rarely"} item={item} />
        <Item title={"Frequently"} item={item} />
        <Item title={"None"} item={item} />
      </Column> */}
    </Box>
  );
};

export default Board;
