import * as React from "react";

import { Grid } from "@mui/material";

const CommonTableGridView = ({ renderCard, rows }) => {
  return (
    <Grid container spacing={2} sx={{ justifyContent: "center" }}>
      {rows.map((row, index) => {
        return (
          // TODO: Should row.ID be the key?
          // TODO: Sizing should be different based on whether left nav is being displayed
          <Grid item key={row.ID} xs={12} sm={6} lg={4}>
            {renderCard(row, index)}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default CommonTableGridView;
