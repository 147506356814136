import React from "react";
import APP from "_machina/react/model/App";
import { useIntl } from "react-intl";
import { LOGGER } from "_machina/util/Logging";
import SCENARIOS_MODEL from "_machina/react/model/ScenariosModel";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Box,
  Button,
} from "@mui/material";
import {
  CommonDialog,
  CommonDialogTabPanel,
} from "_machina/react/components/common/dialog/CommonDialog";
import CommonMenu from "_machina/react/components/common/CommonMenu";
import AddIcon from "@mui/icons-material/Add";

let _setTargets = null;
let _setValues = null;
let _setDialogOpen = null;
let _setSelectedTargetId = null;
let _setSelectedValues = null;
let _setChanged = null;

export async function openTargetDialog() {
  const targets = SCENARIOS_MODEL.getSubModelTargets();
  const selectedTargetId = SCENARIOS_MODEL.getSelectedSubModelTarget();

  _setTargets(targets);
  _setSelectedTargetId(selectedTargetId.getId());
  _setValues(SCENARIOS_MODEL.getTargetDefaultValues());
  _setSelectedValues(SCENARIOS_MODEL.getTargetValues());
  _setChanged(false);
  _setDialogOpen(true);
}

export default function TargetDialog() {
  const [targets, setTargets] = React.useState(null);
  const [selectedValues, setSelectedValues] = React.useState([]);
  const [values, setValues] = React.useState(null);
  const [selectedTargetId, setSelectedTargetId] = React.useState(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [changed, setChanged] = React.useState(false);

  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const intl = useIntl();

  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  _setTargets = setTargets;
  _setValues = setValues;
  _setDialogOpen = setDialogOpen;
  _setSelectedTargetId = setSelectedTargetId;
  _setChanged = setChanged;
  _setSelectedValues = setSelectedValues;

  const callback = async (ok) => {
    if (ok && changed) {
      try {
        await SCENARIOS_MODEL.updateTarget(selectedTargetId, selectedValues);
      } catch (e) {
        LOGGER.error(intl.formatMessage({ id: "error-updating-target" }), e);
        APP.showErrorMessage(
          intl.formatMessage({ id: "error-updating-target" })
        );
      }
    }
    setDialogOpen(false);
  };

  const [tab, setTab] = React.useState("one");

  if (!dialogOpen) return null;

  const handleSelectChange = (selectedValue) => {
    if (!selectedValues.includes(selectedValue)) {
      setSelectedValues([...selectedValues, selectedValue]);
      setChanged(true);
    }
    closeMenu();
  };

  const handleChipDelete = (item) => () => {
    const updatedValues = selectedValues.filter((value) => value !== item);
    setSelectedValues(updatedValues);
    setChanged(true);
  };

  return (
    <CommonDialog
      title={"Select Target Outcome"}
      open={dialogOpen}
      callback={callback}
      tab={tab}
      setTab={setTab}
      fullWidth={true}
      maxWidth={"sm"}
      height={400}
      okDisabled={selectedValues.length >= 1 ? false : true}
      tabs={[
        {
          value: "one",
          label: "nada",
        },
      ]}
      tabPanels={
        dialogOpen && (
          <>
            <CommonDialogTabPanel tab={tab} value={"one"}>
              <FormControl
                variant="outlined"
                sx={{ m: 0, minWidth: 200, width: "93%", mb: 2 }}
              >
                <>
                  <InputLabel>Target</InputLabel>
                  <Select
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    value={selectedTargetId}
                    onChange={async (e) => {
                      try {
                        const subModelId = e.target.value;
                        const defaultValues =
                          await SCENARIOS_MODEL.retrieveTargetDefaultValues(
                            subModelId
                          );
                        setValues(defaultValues);
                        setSelectedValues([]);
                        setSelectedTargetId(subModelId);
                        setChanged(true);
                      } catch (e) {
                        LOGGER.error(
                          intl.formatMessage({ id: "error-loading-target" }),
                          e
                        );
                        APP.showErrorMessage(
                          intl.formatMessage({ id: "error-loading-target" })
                        );
                      }
                    }}
                    label="Target"
                  >
                    {Object.values(targets).map((target, index) => (
                      <MenuItem
                        sx={{ minWidth: "200px" }}
                        key={index}
                        value={target.getId()}
                      >
                        {target.getFormattedName()}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              </FormControl>

              <Button startIcon={<AddIcon />} onClick={handleMenuOpen}>
                Add value
              </Button>
              {menuAnchorEl && (
                <CommonMenu
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  transformOrigin={{ vertical: "top", horizontal: "left" }}
                  anchorEl={menuAnchorEl}
                  onClose={closeMenu}
                >
                  {values.map((value, index) => (
                    <MenuItem
                      key={index}
                      value={value}
                      data-value={value}
                      onClick={() => handleSelectChange(value)}
                    >
                      {SCENARIOS_MODEL.getFormattedTargetValue(
                        selectedTargetId,
                        value
                      )}
                    </MenuItem>
                  ))}
                </CommonMenu>
              )}
              <Box
                sx={{
                  border: "2px solid #3a496b",
                  backgroundColor: "#41527c",
                  padding: "15px",
                  marginTop: "15px",
                  borderRadius: 2,
                }}
              >
                {selectedValues.length > 0
                  ? selectedValues.map((item, index) => (
                      <Chip
                        key={index}
                        label={SCENARIOS_MODEL.getFormattedTargetValue(
                          selectedTargetId,
                          item
                        )}
                        onDelete={handleChipDelete(item)}
                        sx={{ m: 0.5 }}
                        color="primary"
                        size="small"
                      />
                    ))
                  : "No target values selected."}
              </Box>
            </CommonDialogTabPanel>
          </>
        )
      }
    />
  );
}
