import { LOGGER } from "./util/Logging";
import STORAGE from "./util/Storage";

class Prefs {
  constructor() {
    this.storage = STORAGE;
    this.prefs = {};
  }

  PREFIX = "machina.";
  PREFERENCES_PROP = this.PREFIX + "prefs";

  load() {
    const { storage, PREFERENCES_PROP } = this;
    try {
      this.prefs = storage.get(PREFERENCES_PROP);
      if (!this.prefs) {
        this.prefs = {};
      }
    } catch (e) {
      LOGGER.error("Error loading preferences: " + e);
    }
  }

  async setPreference(name, value) {
    this.prefs[name] = value;
    this.save();
  }

  getPreference(name, defaultValue) {
    return this.prefs[name] ? this.prefs[name] : defaultValue;
  }

  getBoolPreference(name, defaultValue) {
    const val = this.prefs[name];
    if (val === undefined) {
      return defaultValue;
    } else {
      return val === true;
    }
  }

  getIntPreference(name, defaultValue) {
    const val = this.getPreference(name, defaultValue);
    try {
      if (val) {
        return parseInt(val);
      }
    } catch (e) {
      LOGGER.error("Preferece is not an integer: " + name + ", " + val);
    }
    return defaultValue;
  }

  save() {
    const { prefs, storage, PREFERENCES_PROP } = this;
    try {
      storage.put(PREFERENCES_PROP, prefs);
    } catch (e) {
      LOGGER.error("Error saving preferences: " + e);
    }
  }
}

const PREFS = new Prefs();

export default PREFS;
