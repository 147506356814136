import React from "react";

import { Grid } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { getDefaultModelUrl } from "_machina/assets/images";

import CommonObjectDetailsStepPanel from "_machina/react/components/common/wizard/CommonObjectDetailsStepPanel";
import IterationsSlider from "../common/IterationsSlider";
import ViewModelSwitch from "../common/ViewModelSwitch";

let modelValidatorCallback = null;

export const createModelDetailsStep = (validator, title) => {
  return {
    key: "modelDetails",
    title: title || <FormattedMessage id="modelDetails" />,
    onNext: (state, setState, forceUpdate) => {
      validator.reset();
      modelValidatorCallback();
      if (validator.getMinInvalidTab() >= 0) {
        forceUpdate();
        return false;
      }
      return true;
    },
    isEnabled: (state) => {
      return true;
    },
  };
};

export function ModelDetailsStep({
  modelDetailsStep,
  step,
  steps,
  state,
  setState,
  validator,
  setChooserState,
  getChooserState,
}) {
  return (
    <CommonObjectDetailsStepPanel
      header={<FormattedMessage id="wizard.dataset.modelDetails.header" />}
      step={step}
      steps={steps}
      stepKey={modelDetailsStep.key}
      defaultImageUrl={getDefaultModelUrl()}
      object={state.model}
      setObject={(object) => {
        state.model = object;
        setState({ ...state });
      }}
      getChooserState={getChooserState}
      setChooserState={setChooserState}
      addValidatorCallback={(cb) => {
        modelValidatorCallback = cb;
      }}
      validator={validator}
      other={
        <>
          <Grid item xs={12}>
            <IterationsSlider state={state} setState={setState} />
          </Grid>
          <Grid item xs={12} sx={{ mt: -2 }}>
            <ViewModelSwitch state={state} setState={setState} />
          </Grid>
        </>
      }
    />
  );
}
